import React, {useEffect, useState} from "react";
import { useSelector } from 'react-redux';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Offcanvas, Button } from 'react-bootstrap';
import DepositNew from "./DepositNew";
import DepositCorefy from "./DepositCorefy";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faAnglesDown } from '@fortawesome/free-solid-svg-icons';

export default function QuickDeposit() {

    const navigate = useNavigate();
    const location = useLocation();

    const [show, setShow] = useState(true);

    const handleClose = () => {
        setShow(false);
        setTimeout(() => { navigate(location.pathname); }, 300);
    };

    const user = useSelector((state) => state.user.data);
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const balance = userLoggedIn ? totalBalance(user) : 0;
    const [timeoutActive, setTimeoutActive] = useState(false);
    const balanceLimit = 1;

    useEffect(() => {
        /** Start timer only on game screen */
        if (!timeoutActive && !show && window.location.pathname.includes('/game/') && balance < balanceLimit) {

            /** Register that timeout had started */
            setTimeoutActive(true);

            /** Set timeout */
            setTimeout(() => {
                /** Check if the user is still on game screen */
                if (!show && window.location.pathname.includes('/game/') && balance < balanceLimit) {
                    handleClose();
                }
            }, 13000)
        }
    })

    return (
        <Offcanvas show={show} onHide={handleClose} placement={'end'} className="quick-deposit quick-deposit-page">
            {/*  */}
            <Offcanvas.Header closeButton className='canvas-header'>
                <h1 className='canvas-title'>Quick Deposit</h1>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {/* {
                    window.location.pathname.includes('/game/') && balance >= balanceLimit && (
                        <p className='game-balance'>Balance: <PlayerBalance /></p>
                    )
                }
                {
                    window.location.pathname.includes('/game/') && balance < balanceLimit && (
                        <div>
                            <p className='game-balance'>
                                Balance: <PlayerBalance />&nbsp;&nbsp;
                                <span className="game-balance-red">
                                    <FontAwesomeIcon icon={faAnglesDown} />&nbsp;&nbsp;Low balance
                                </span>
                                <br/>
                                <span className='game-balance-subhead'>
                                    Add funds using quick deposit below.
                                </span>
                            </p>
                        </div>
                    )
                } */}
                {/* <Deposit /> */}
                <DepositCorefy isQuickDeposit={true} />
                <br />
                <br />
                <br />
            </Offcanvas.Body>
            {/* <div className="close-container">
                <Button onClick={handleClose} className="btn-mobile-menu-close" >
                    <FontAwesomeIcon icon={faTimes} className='me-3' />
                    <span>Close</span>
                </Button>
            </div> */}
        </Offcanvas>
    )
}

function totalBalance(user) {
    const real = parseFloat(user.wagerBalance) + parseFloat(user.withdrawableBalance);
    const bonus = parseFloat(user.bonus[0]?.bonusDepositBalance || 0) + parseFloat(user.bonus[0]?.bonusDepositWinnings || 0) + parseFloat(user.bonus[0]?.bonusFreeBalance || 0) + parseFloat(user.bonus[0]?.bonusFreeWinnings || 0);
    return parseFloat(real) + parseFloat(bonus);
}