
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export default function WithdrawSummary({ sign, amount }) {
    return (
        <div className="deposit-summary">
            <div>
                <p className="mb-0">Amount: <span className="white-100">{sign}{amount}</span></p>
            </div>
            <div>
                <FontAwesomeIcon icon={faCheck} className="hpf-check-icon" />
            </div>
        </div>
    )
}