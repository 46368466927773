import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Navbar, Nav, Offcanvas, Button, Modal } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faGamepad, faQuestionCircle, faCreditCard, faHome, faTimes, faSearch, faUser, faAngleLeft, faChevronRight, faAnglesDown, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { openGleap } from "../utils/itinialize-gleap";

import NavLink from './NavLink';
import Deposit from '../pages/Deposit';
import DepositNew from '../pages/DepositNew';
import DepositCorefy from '../pages/DepositCorefy';
import logo from '../data/images/logo-color.png';
import logoSeparate from '../data/images/logo-cherry-white.png';

import './navigation.scss'

export default function Navigation() {
    const location = useLocation();
    const navigate = useNavigate();
    const locale = useSelector((state) => state.globalContent.data.locale);
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const user = useSelector((state) => state.user.data);
    const balance = userLoggedIn ? totalBalance(user) : 0;

    /** States */
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [showQuickDeposit, setShowQuickDeposit] = useState(false);
    const [showModalHelp, setShowModalHelp] = useState(false);
    const [hasBackground, setHasBackground] = useState(false);
    const [orientation, setOrientation] = useState('portrait');

    /** Functions */
    const mobileMenuToggle = () => setShowMobileMenu(!showMobileMenu);
    const quickDepositToggle = () => setShowQuickDeposit(!showQuickDeposit);
    const modalHelpClick = () => {
        if (userLoggedIn) {
            openGleap(user)
        }
        else {
            setShowModalHelp(true);
        }
    }

    const searchClick = () => {
        const input = document.querySelector("[id='gameSearchInput']");

        if (input) {
            input.click();
            input.focus();
            return;
        }
        navigate(`/${locale}/games/lobby?search`);
    };

    const scrollHandler = () => {
        if (window.scrollY < 3 && hasBackground) {
            setHasBackground(false);
        }

        if (window.scrollY > 3 && !hasBackground) {
            setHasBackground(true);
        }
    }

    const resizeHandler = (e) => {
        if (isMobile && orientation === 'portrait' && window.innerWidth > window.innerHeight) {
            console.log('landscape')
            setOrientation('landscape')
        }

        if (isMobile && orientation === 'landscape' && window.innerWidth < window.innerHeight) {
            console.log('portrait')
            setOrientation('portrait')
        }
    }

    useEffect(() => {
        document.addEventListener('scroll', scrollHandler);

        if (isMobile) {
            window.addEventListener("resize", resizeHandler);

            if (orientation === 'portrait' && window.innerWidth > window.innerHeight) {
                console.log('landscape')
                setOrientation('landscape')
            }
        }


        return () => {
            document.removeEventListener('scroll', scrollHandler);
            window.removeEventListener("resize", resizeHandler);
        }
    })

    //////////////////
    /// Do not show navigation on the blocked IP
    /////////////////
    if (location.pathname.includes('region-blocked')) {
        return null;
    }

    //////////////////
    /// Game page navigation
    /////////////////
    if (location.pathname.includes('/game/')) {
        return (
            <>
                <Navbar className={`navbar-game navbar-game-${orientation}`}>
                    <div className="navbar-game-wrapper">
                        <Nav className="navbar-back-links">
                            <Button className="navbar-game-link" onClick={() => { window.history.back(); }} >
                                <FontAwesomeIcon icon={faAngleLeft} />
                            </Button>
                            <Link className="navbar-game-link" to={`/${locale}`}>
                                <FontAwesomeIcon icon={faHome} />
                            </Link>
                        </Nav>
                        <Nav>
                            <Button
                                variant="light"
                                className={`navbar-game-link btn-add-funds ${balance < 1 ? 'btn-add-funds-red' : ''}`}
                                onClick={quickDepositToggle}
                            >
                                {/* <PlayerBalance />  */}
                                {/* Add funds */}
                                <FontAwesomeIcon icon={faCreditCard} />
                                {/* <FontAwesomeIcon icon={faPlus} /> */}
                                {balance < 1 && (
                                    // <div className='btn-add-funds-alert'></div>
                                    <FontAwesomeIcon className='btn-add-funds-alert' icon={faCircleExclamation} />
                                )}
                            </Button>
                        </Nav>
                    </div>
                </Navbar>
                <QuickDeposit show={showQuickDeposit} quickDepositToggle={quickDepositToggle} />
            </>
        )
    }

    return (
        <>
            {/* ////////////////////// */}
            {/* Top Desktop navigation*/}
            {/* ////////////////////// */}
            <Navbar className={`navbar-top ${hasBackground ? 'navbar-color' : ''} d-none d-sm-none d-md-flex`}>
                <Container fluid="lg">

                    {/* Logo */}
                    <Link to={`/${locale}`} className={`navbar-brand brand`}>
                        <img src={logo} className="logo" alt="Cherry Spins" />
                    </Link>

                    {/* Navigation - left */}
                    <Nav className="me-auto">
                        <NavLink to={`/${locale}`} className="navbar-link" activeClassName="navbar-link-active" end>Home</NavLink>
                        <NavLink to={`/${locale}/games`} className="navbar-link" activeClassName="navbar-link-active">Games</NavLink>
                        {/* <a href="https://cherryspins.support" className="navbar-link" target="_blank" rel="noreferrer">Help</a> */}
                        <Button onClick={modalHelpClick} className="navbar-link" variant='link'>Help</Button>
                    </Nav>

                    {/* Navigation - right */}
                    <Nav>
                        {userLoggedIn && (
                            <>
                                <Button onClick={quickDepositToggle} className="navbar-balance">
                                    <PlayerBalance /> &nbsp;&nbsp;
                                    <FontAwesomeIcon icon={faCreditCard} />
                                </Button>
                                <Link to={`/${locale}/profile`} className="navbar-profile">
                                    <FontAwesomeIcon icon={faUser} />
                                </Link>
                            </>
                        )}

                        {!userLoggedIn && (
                            <>
                                <Link to={`${location.pathname}?login`} className="btn btn-main me-2">Log in</Link>
                                <Link to={`${location.pathname}?register`} className="btn btn-secondary">Register</Link>
                            </>
                        )}
                    </Nav>
                </Container>
            </Navbar>

            {/* ////////////////////// */}
            {/* Top Mobile navigation | Logged Out */}
            {/* ////////////////////// */}
            {!userLoggedIn && (
                <Navbar className={`navbar-top ${hasBackground ? 'navbar-color' : ''} d-sm-flex d-md-none`}>
                    <Container fluid >

                        {/* Logo */}
                        <Link className="navbar-brand brand" to={`/${locale}`}>
                            <img src={logo} className="logo" alt='Cherry Spins' />
                        </Link>

                        {/* Navigation - right */}
                        <Nav>
                            <Link className={`btn btn-main me-2`} to={`${location.pathname}?login`}>Log in</Link>
                            <Link className={`btn btn-secondary`} to={`${location.pathname}?register`}>Register</Link>
                        </Nav>
                    </Container>
                </Navbar>
            )}

            {/* ////////////////////// */}
            {/* Top Mobile navigation | Logged In */}
            {/* ////////////////////// */}
            {userLoggedIn && (
                <Navbar className="navbar-top-logged-in-mobile d-sm-flex d-md-none">
                    <Container fluid>

                        {/* Navigation - left */}
                        <Nav className="me-auto">
                            <span className="navbar-name">{`${user.firstName} ${user.lastName}`}</span>
                        </Nav>

                        {/* Navigation - right */}
                        <Nav>
                            <span className="navbar-balance-mobile">Balance: &nbsp; <PlayerBalance /></span>
                        </Nav>
                    </Container>
                </Navbar>
            )}


            {/* ////////////////////// */}
            {/* Bottom Mobile navigation */}
            {/* ////////////////////// */}
            <Navbar className="d-sm-flex d-md-none navbar-bottom">
                <Container fluid className='ps-0 pe-0'>

                    {/* Navigation - left */}
                    <Nav className="w-100 justify-content-around">

                        {/* Home */}
                        <NavLink to={`/${locale}`} className="navbar-bottom-link navbar-bottom-link-logo" activeClassName="navbar-bottom-link-active navbar-bottom-link-logo-active" end>
                            <img src={logoSeparate} />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {/* <FontAwesomeIcon icon={faHome} /> */}
                        </NavLink>

                        {/* Games */}
                        <NavLink to={`/${locale}/games`} className="navbar-bottom-link" activeClassName="navbar-bottom-link-active"> <FontAwesomeIcon icon={faGamepad} /></NavLink>

                        {/* Deposit */}
                        {userLoggedIn && (
                            <Button className="navbar-bottom-link" onClick={quickDepositToggle}> <FontAwesomeIcon icon={faCreditCard} /></Button>
                        )}

                        {/* Search */}
                        <Button className="navbar-bottom-link navbar-bottom-search" onClick={searchClick}>
                            <FontAwesomeIcon icon={faSearch} /></Button>

                        {/* Mobile menu button */}
                        <Button onClick={mobileMenuToggle} className="btn-mobile-menu-open"><FontAwesomeIcon icon={faBars} /></Button>

                    </Nav>
                </Container>
            </Navbar>

            {/* ////////////////////// */}
            {/* Side Menu */}
            {/* ////////////////////// */}
            <Offcanvas show={showMobileMenu} onHide={mobileMenuToggle} placement={'end'} className="mobile-menu">
                {/* <Offcanvas.Header closeButton /> */}
                <Offcanvas.Body className="d-flex flex-column justify-content-start ps-0 pe-0 pt-0 pb-0">

                    {/* Logo */}
                    <div className="mobile-menu-logo-wrapper">
                        <img src={logo} className="mobile-menu-logo" alt='Cherry Spins' />
                    </div>

                    {/* Navigation */}
                    <div className="mobile-menu-container mb-3">

                        <NavLink className="mobile-menu-link" activeClassName="mobile-menu-link-active" to={`/${locale}`} onClick={mobileMenuToggle} end>
                            <FontAwesomeIcon icon={faHome} className='me-3' />
                            <span>Home</span>
                        </NavLink>

                        <NavLink className="mobile-menu-link" activeClassName="mobile-menu-link-active" to={`/${locale}/games`} onClick={mobileMenuToggle}>
                            <FontAwesomeIcon icon={faGamepad} className='me-3' />
                            <span>Games</span>
                        </NavLink>

                        {/* <a className="mobile-menu-link" href="https://cherryspins.support" target="_blank" rel="noreferrer" onClick={mobileMenuToggle}>
                            <FontAwesomeIcon icon={faQuestionCircle} className='me-3' />
                            <span>Help</span>
                        </a> */}

                        <a className="mobile-menu-link" href="#" onClick={() => { modalHelpClick(); mobileMenuToggle(); }}>
                            <FontAwesomeIcon icon={faQuestionCircle} className='me-3' />
                            <span>Help</span>
                        </a>

                        {userLoggedIn && (
                            <NavLink className="mobile-menu-link" activeClassName="mobile-menu-link-active" to={`/${locale}/profile`} onClick={mobileMenuToggle}>
                                <FontAwesomeIcon icon={faUser} className='me-3' />
                                <span>Profile</span>
                            </NavLink>
                        )}
                    </div>

                    {/* Login section */}
                    <div className="btn-profile-wrapper">
                        {!userLoggedIn &&
                            <>
                                <Link to={`${location.pathname}?login`} className="btn btn-main-lg btn-mobile-menu-login mb-3" onClick={mobileMenuToggle}>Log in</Link>

                                <Link to={`${location.pathname}?register`} className="btn btn-secondary-lg btn-mobile-menu-register mb-3" onClick={mobileMenuToggle}>Register</Link>
                            </>
                        }

                        {userLoggedIn &&
                            <>
                                <Link to={`/${locale}/profile/deposit`} className="btn btn-main-lg btn-mobile-menu-deposit mb-3" onClick={mobileMenuToggle}>Deposit</Link>

                                <Link to={`/${locale}/profile`} className="text-start mobile-menu-balance mb-3" onClick={mobileMenuToggle}>
                                    <div>

                                        <p className="player-name">{`${user.firstName} ${user.lastName}`}</p>

                                        <p className="player-balance"><PlayerBalance /></p>

                                    </div>
                                    <FontAwesomeIcon icon={faChevronRight} className="mobile-menu-balance-arrow" />
                                </Link>
                            </>
                        }
                    </div>

                    {/* Close button */}
                    <Button onClick={mobileMenuToggle} className="btn-mobile-menu-close" >
                        <FontAwesomeIcon icon={faTimes} className='me-3' />
                        <span>Close</span>
                    </Button>

                </Offcanvas.Body>
            </Offcanvas>

            {/* ////////////////////// */}
            {/* Quick Deposit */}
            {/* ////////////////////// */}
            <QuickDeposit show={showQuickDeposit} quickDepositToggle={quickDepositToggle} />

            <ModalHelp show={showModalHelp} setShow={setShowModalHelp} />
        </>
    );
}

function totalBalance(user) {
    const real = parseFloat(user.wagerBalance) + parseFloat(user.withdrawableBalance);
    const bonus = parseFloat(user.bonus[0]?.bonusDepositBalance || 0) + parseFloat(user.bonus[0]?.bonusDepositWinnings || 0) + parseFloat(user.bonus[0]?.bonusFreeBalance || 0) + parseFloat(user.bonus[0]?.bonusFreeWinnings || 0);
    return parseFloat(real) + parseFloat(bonus);
}

function PlayerBalance() {

    const user = useSelector((state) => state.user.data);
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);

    function roundNumber(num) {
        return Math.round((num + Number.EPSILON) * 100) / 100;
    }

    useEffect(() => { })

    return !userLoggedIn ? null :
        <span>
            {user.currency.sign}
            {roundNumber(userLoggedIn ? totalBalance(user) : 0)}
        </span>
}

function QuickDeposit({ show, quickDepositToggle, gameScreen }) {
    // const location = useLocation();
    const user = useSelector((state) => state.user.data);
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const balance = userLoggedIn ? totalBalance(user) : 0;
    const [timeoutActive, setTimeoutActive] = useState(false);
    const balanceLimit = 1;

    useEffect(() => {
        /** Start timer only on game screen */
        if (!timeoutActive && !show && window.location.pathname.includes('/game/') && balance < balanceLimit) {

            /** Register that timeout had started */
            setTimeoutActive(true);

            /** Set timeout */
            setTimeout(() => {
                /** Check if the user is still on game screen */
                if (!show && window.location.pathname.includes('/game/') && balance < balanceLimit) {
                    quickDepositToggle();
                }
            }, 13000)
        }
    })

    return (
        <Offcanvas show={show} onHide={quickDepositToggle} placement={'end'} className="quick-deposit">
            {/*  */}
            <Offcanvas.Header closeButton className='canvas-header'>
                <h1 className='canvas-title'>Quick Deposit</h1>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {
                    window.location.pathname.includes('/game/') && balance >= balanceLimit && (
                        <div className="game-balance-wrapper">
                            <p className='game-balance'>Balance: <PlayerBalance /></p>
                        </div>
                    )
                }
                {
                    window.location.pathname.includes('/game/') && balance < balanceLimit && (
                        <div className="game-balance-wrapper">
                            <p className='game-balance'>
                                Balance: <PlayerBalance />&nbsp;&nbsp;
                                <span className="game-balance-red">
                                    <FontAwesomeIcon icon={faAnglesDown} />&nbsp;&nbsp;Low balance
                                </span>
                                <br />
                                <span className='game-balance-subhead'>
                                    Add funds using quick deposit below.
                                </span>
                            </p>
                        </div>
                    )
                }
                {/* <Deposit /> */}
                <DepositCorefy isQuickDeposit={true} />
                <br />
                <br />
                <br />
            </Offcanvas.Body>
            <div className="close-container">
                {/* <Button variant="light" onClick={quickDepositToggle}>Close</Button> */}
                {/* Close button */}
                <Button onClick={quickDepositToggle} className="btn-mobile-menu-close" >
                    <FontAwesomeIcon icon={faTimes} className='me-3' />
                    <span>Close</span>
                </Button>
            </div>
        </Offcanvas>
    )
}

function ModalHelp({ show, setShow }) {
    const locale = useSelector((state) => state.globalContent.data.locale);

    return (
        <Modal show={show} onHide={() => { setShow(false) }} centered>
            <Modal.Header closeButton>
                <Modal.Title>Help & Support</Modal.Title>
            </Modal.Header>
            <Modal.Body className='text-center'>
                <p className='pb-2'>Contact our support team at <span className='white-100'>info@cherryspins.support</span> or</p>
                <div className='d-block pb-3'>
                    <a href={`/${locale}/profile`} className="btn-main">
                        Log in and chat with an agent
                    </a>
                </div>
            </Modal.Body>
        </Modal>
    )
}