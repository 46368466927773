import { createSlice } from "@reduxjs/toolkit";
import { dbUserFromToken } from '../utils/db-functions'

export const user = createSlice({
    name: "user",
    initialState: {
        isLoading: true,
        isLoggedIn: false,
        data: {},
        ipData: {}
    },
    reducers: {
        updateUser: (state, action) => {
            Object.assign(state, { ...state, ...action.payload });
        },
        updateUserData: (state, action) => {
            Object.assign(state, { ...state, ...action.payload });
        },
        logOutUser: (state) => {
            Object.assign(state, { ...state, ...{
                    isLoggedIn: false,
                    data: {}
                }
            });
        }
    },
});

export const checkUserAsync = () => (dispatch) => {
    dbUserFromToken()
        .then(res => {
            if (res?.data?.user) {

                /* USER LOGGED IN */

                dispatch(
                    updateUser({
                        isLoading: false,
                        isLoggedIn: true,
                        data: res.data.user,
                    })
                );
            }
            else {
                dispatch(
                    updateUser({
                        isLoading: false,
                        isLoggedIn: false,
                    })
                );
            }
        })
        .catch(err => {
            dispatch(
                updateUser({
                    isLoading: false,
                    isLoggedIn: false
                })
            );
        })
};

// Action creators are generated for each case reducer function
export const {
    updateUser,
    updateUserData,
    logOutUser
} = user.actions;

export default user.reducer;