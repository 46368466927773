import Axios from 'axios';
import 'dotenv/config';

export const dbBaseURL = process.env.REACT_APP_PAYLOAD_URL;

/////////////
/// USER FUNCTIONS
/////////////
export const dbUserFromToken = () => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${dbBaseURL}/api/players/me`
    }).then(res => {
        if (res.data.user && res?.data?.user?.isActive) {
            dbUpdateSessionLogin();
            window.sessionStorage.setItem('loggedIn', 'true');
            return res;
        }
        else {
            return { errors: [{ message: 'Your account has been blocked. Please, contact support.' }] }
        }
    });
}

export const dbLogin = (email, password) => {
    return Axios({
        method: 'post',
        data: {
            email: email,
            password: password
        },
        withCredentials: true,
        url: `${dbBaseURL}/api/players/login`
    }).then(res => {
        if (res.data.user && res?.data?.user?.isActive) {
            dbUpdateSessionLogin();
            window.sessionStorage.setItem('loggedIn', 'true');
            return res;
        }
        else {
            return { errors: [{ message: 'Your account has been blocked. Please, contact support.' }] }
        }
    });
}

export const dbLogout = async () => {

    /** Register logout */
    await dbUpdateSessionLogout()

    window.sessionStorage.removeItem('loggedIn');

    /** Return logout function */
    return Axios({
        method: 'post',
        withCredentials: true,
        url: `${dbBaseURL}/api/players/logout`
    })
}

export const dbCheckEmailExists = (email) => {
    return Axios({
        method: 'post',
        data: { email },
        withCredentials: true,
        url: `${dbBaseURL}/utility/check-email-exists`
    })
}

export const dbRegister = (data) => {
    return Axios({
        method: 'post',
        data: data,
        withCredentials: true,
        url: `${dbBaseURL}/api/players`
    })
}

export const dbForgotPassword = (email) => {
    return Axios({
        method: 'post',
        data: {
            email: email,
            origin: window.location.origin + window.location.pathname
        },
        withCredentials: true,
        url: `${dbBaseURL}/api/players/forgot-password`
    })
}

export const dbResetPassword = (token, password) => {
    return Axios({
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            token: token,
            password: password
        },
        withCredentials: true,
        url: `${dbBaseURL}/api/players/reset-password`
    })
}

export const dbUpdatePlayedGames = (data) => {
    return Axios({
        method: 'post',
        data: data,
        withCredentials: true,
        url: `${dbBaseURL}/utility/update-played-games`
    })
}

export const dbUpdateSessionLogin = () => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${dbBaseURL}/utility/login`
    })
}

export const dbUpdateSessionLogout = () => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${dbBaseURL}/utility/logout`
    })
}

export const dbSendWelcomeEmail = () => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${dbBaseURL}/utility/send-welcome-email`
    })
}


/////////////
/// CONTENT FUNCTIONS
/////////////
export const dbGetCountries = () => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${dbBaseURL}/api/countries?limit=1000&sort=name`
    })
}

export const dbCheckCountryAndLanguage = (countryURL, languageURL) => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${dbBaseURL}/api/countries?limit=1000`
    }).then(res => {
        if (res.data.totalDocs < 1) { throw new Error('No counties exist'); }

        /** Searching for current country from the url among supported countries */
        let country = res.data.docs.filter(country => country.ISOcode === countryURL)[0];

        if (!country) {
            /** Choose a default country */
            country = res.data.docs.filter(country => country.isDefaultCountry === true)[0];
            if (!country) { throw new Error('No default country exists'); }
        }

        /** Check if language from the url is supported */
        let language = (country.defaultLanguage === languageURL || country.additionalLanguages.includes(languageURL)) ?
            languageURL :
            country.defaultLanguage;

        return {
            country: country,
            language: language,
        }
    });
}

export const dbGetGlobalContent = (language) => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${dbBaseURL}/api/globals/global_content?depth=0&locale=${language}`
    })
}

export const dbGetGames = (country, language) => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${dbBaseURL}/api/game_categories?where[url][equals]=all_games&locale=${language}&limit=1` //where[country][equals]=${country.id}&
    })
}

export const dbGetPromotions = (country, language) => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${dbBaseURL}/api/promotions?where[isActive][equals]=true&limit=1000&locale=${language}` //where[country][equals]=${country.id}&
    })
}

export const dbGetPromotion = (id) => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${dbBaseURL}/api/promotions?where[id][equals]=${id}&where[isActive][equals]=true&limit=1000`
    })
}

export const dbGetBonuses = (country, language) => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${dbBaseURL}/api/bonuses?where[isActive][equals]=true&limit=1000&locale=${language}&sort=rank` //where[country][equals]=${country.id}&
    })
}

export const dbGetGameCategories = (country, language) => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${dbBaseURL}/api/game_categories?locale=${language}&limit=1000&depth=1` //where[country][equals]=${country.id}&
    })
}

export const dbGetGameList = (array) => {
    const list = array.join(',');
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${dbBaseURL}/api/games?where[id][in]=${list}&depth=1`
    })
}

export const dbGetGameUuidList = (array) => {
    const list = array.join(',');
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${dbBaseURL}/api/games?where[uuid][in]=${list}&where[isActive][equals]=true&depth=1`
    })
}

export const dbGetGame = (id) => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${dbBaseURL}/api/games?where[isActive][equals]=true&where[id][equals]=${id}&limit=1`
    })
}

export const dbGetAllGames = (page) => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${dbBaseURL}/api/games?where[isActive][equals]=true&depth=0&limit=24&page=${page}&sort=providerRank`
    })

}

export const dbGetGameByTagAndProvider = (tag) => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${dbBaseURL}/api/games?where[tags][contains]=${tag}&where[isActive][equals]=true&depth=0&limit=1&sort=providerRank`
    })
}

export const dbGetGamesByTagAndProvider = (tag, page) => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${dbBaseURL}/api/games?where[tags][contains]=${tag}&where[isActive][equals]=true&depth=0&limit=24&page=${page}&sort=providerRank`
    })
}

export const dbSearchGames = (search, page) => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${dbBaseURL}/api/games?where[search][contains]=${search}&where[isActive][equals]=true&limit=24&page=${page}`
    })
}

export const dbGetGamesByProviderName = (name) => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${dbBaseURL}/api/games?where[provider][equals]=${name}&where[isActive][equals]=true&sort=providerRank&limit=1000`
    })
}


/////////////
/// PAYMENT FUNCTIONS
/////////////
export const dbPraxisDeposit = (data) => {
    return Axios({
        method: 'post',
        data: data,
        withCredentials: true,
        url: `${dbBaseURL}/praxis/deposit`
    })
}

export const dbCorefyDeposit = (data) => {
    return Axios({
        method: 'post',
        data: data,
        withCredentials: true,
        url: `${dbBaseURL}/corefy/deposit`
    })
}

export const dbCorefyWithdraw = (data) => {
    return Axios({
        method: 'post',
        data: data,
        withCredentials: true,
        url: `${dbBaseURL}/corefy/withdraw`
    })
}

export const dbPraxisOpenHPFSession = (data) => {
    return Axios({
        method: 'post',
        data: data,
        withCredentials: true,
        url: `${dbBaseURL}/praxis/open-hpf-session`
    })
}

export const dbPraxisHPFDeposit = (data) => {
    return Axios({
        method: 'post',
        data: data,
        withCredentials: true,
        url: `${dbBaseURL}/praxis/hpf-deposit`
    })
}

export const dbPraxisWithdraw = (data) => {
    return Axios({
        method: 'post',
        data: data,
        withCredentials: true,
        url: `${dbBaseURL}/praxis/withdraw`
    })
}

export const dbGetGatewayOptions = () => {
    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${dbBaseURL}/praxis/get-gateway-options`
    })
}

export const dbPraxisAPMDeposit = (data) => {
    return Axios({
        method: 'post',
        data: data,
        withCredentials: true,
        url: `${dbBaseURL}/praxis/apm-deposit`
    })
}


/////////////
/// HISTORY FUNCTIONS
/////////////
export const dbGetTransactions = (type, page, userId) => {
    let path = '';

    switch (type) {
        case 'game':
            path = 'game_transactions?where[amount][not_equals]=0';
            break;
        case 'payment':
            path = 'payment_transactions?where[transaction_status][equals]=approved';
            break;
        case 'bonus':
            path = 'bonus_transactions?';
            break;
        default:
            path = '';
    }

    return Axios({
        method: 'get',
        withCredentials: true,
        url: `${dbBaseURL}/api/${path}&where[playerId][equals]=${userId}&depth=0&limit=24&page=${page}sort=createdAt`
    })
}

/////////////
/// GAME FUNCTIONS
/////////////
export const dbLaunchGame = (data) => {
    if (data.source === 'pragmatic-play') {
        return Axios({
            method: 'post',
            data: data,
            withCredentials: true,
            url: `${dbBaseURL}/pragmatic-play/launch-game`
        })
    }

    return Axios({
        method: 'post',
        data: data,
        withCredentials: true,
        url: `${dbBaseURL}/apigator/launch-game`
    })
}


/////////////
/// BONUS FUNCTIONS
/////////////
export const dbAssignFreeBonus = (data) => {
    return Axios({
        method: 'post',
        data: data,
        withCredentials: true,
        url: `${dbBaseURL}/bonuses/assign-free-bonus`
    })
}

export const dbAssignLimitedTimeFreeBonus = (data) => {
    return Axios({
        method: 'post',
        data: data,
        withCredentials: true,
        url: `${dbBaseURL}/bonuses/assign-free-limited-bonus`
    })
}


/////////////
/// FREESPINS FUNCTIONS
/////////////
export const checkAvailableFreespins = (data) => {
    return new Promise(async (resolve, rej) => {
        const apigatorCheck = Axios({
            method: 'post',
            data: data,
            withCredentials: true,
            url: `${dbBaseURL}/apigator/freespins/get`
        })

        const pragmaticCheck = Axios({
            method: 'post',
            data: data,
            withCredentials: true,
            url: `${dbBaseURL}/pragmatic-play/freespins/get`
        })

        const [apigatorResult, pragmaticResult] = await Promise.all([apigatorCheck, pragmaticCheck]);

        console.log(apigatorResult.data)
        console.log(pragmaticResult.data)

        if (apigatorResult?.data?.games?.length > 0) {
            resolve(apigatorResult);
            return;
        }

        if (pragmaticResult?.data?.games?.length > 0) {
            resolve(pragmaticResult);
            return;
        }
    })
}


/////////////
/// ADDRESS FUNCTIONS
/////////////
export const dbAddressLookup = (params) => {
    return Axios({
        method: 'get',
        headers: {
            'x-api-key': process.env.REACT_APP_POSTGRID_API_KEY
        },
        url: `https://api.postgrid.com/v1/addver/completions?partialStreet=${params}&countryFilter=CA&provInsteadOfPC=true`
    })
}

export const dbAddressComplete = (data) => {
    return Axios({
        method: 'post',
        headers: {
            'x-api-key': process.env.REACT_APP_POSTGRID_API_KEY
        },
        data: data,
        url: `https://api.postgrid.com/v1/addver/completions?index=0`
    })
}


/////////////
/// IP FUNCTION
/////////////
export const dbIPLookup = () => {
    return Axios({
        method: 'get',
        url: `${dbBaseURL}/utility/ip-lookup`
    })
}

/////////////
/// PHONE VERIFICATION FUNCTIONS
/////////////
export const dbSendVerificationCode = (data) => {
    return Axios({
        method: 'post',
        data: data,
        withCredentials: true,
        url: `${dbBaseURL}/utility/send-verification-code`
    })
}

export const dbCheckVerificationCode = (data) => {
    return Axios({
        method: 'post',
        data: data,
        withCredentials: true,
        url: `${dbBaseURL}/utility/check-verification-code`
    })
}