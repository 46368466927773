
import React from "react";

export default function Fallback() {
    return (
        <div className="fallback-meter">
            <span>
                <span className="fallback-progress"></span>
            </span>
        </div>
    )
}