
import React, { useEffect, useState } from "react";
import { Container } from 'react-bootstrap';
import { dbGetGamesByTagAndProvider } from "../utils/db-functions";
import uniqid from 'uniqid';

import GameTile from "./GameTile";
import LoadMore from "./LoadMore";

export default function TagsList({ tag }) {
    const placeholder = new Array(10).fill(null);
    const [games, setGames] = useState([]);
    const [currentTag, setCurrentTag] = useState('');
    const [pages, setPages] = useState({
        page: 1,
        total: null
    })

    const fetchPage = (tagWord, pageNumber) => {
        //console.log('fetch')
        const currentGames = pageNumber === 1 ? [] : games;

        dbGetGamesByTagAndProvider(tagWord, pageNumber)
            .then(res => {
                if (!res.data) { return; }
                setGames([...currentGames, ...res.data.docs])
                setCurrentTag(tagWord);
                setPages({ page: pageNumber, total: res.data.totalPages })
            })
    }

    useEffect(() => {
        if (tag !== currentTag) { fetchPage(tag, 1); }
        // console.log('works')
    }, [pages.page, tag])

    return (
        <Container fluid="lg" className={`game-category-container`}>
            <h3 className="h3">{tag ? tag : 'Loading...'}</h3>

            <div className={`game-list-wrapper mt-3`}>
                {
                    games.length === 0 ? placeholder.map((game) => <GameTile game={game} key={uniqid()} />) :
                        games.map((game, i) => <GameTile game={game} key={`tag-list-${game.id}-${i}`} />)
                }
            </div>

            <LoadMore
                isVisible={pages.total !== null && pages.page < pages.total}
                onClick={() => { fetchPage(currentTag, pages.page + 1) }}
            />
        </Container>
    );
}