import React, { useEffect, useState, useRef } from "react";
import { Button, FloatingLabel, Form, Accordion, Stack, Alert } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faCircleCheck, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { voluumSendPostback } from "../utils/voluum";
import { Helmet } from "react-helmet-async";

import validateBonus from "../utils/validate-bonus";
import paymentLogos from "../data/images/payment-logos.svg";
import PraxisHPFDeposit from "../components/PraxisHPFDeposit";
import PraxisIFrameDeposit from "../components/PraxisIFrameDeposit";
import ChoosePaymentMethodCorefy from "../components/ChoosePaymentMethodСorefy";
import PraxisAPMDeposit from "../components/PraxisAPMDeposit";
import ScrollButtons from "../components/ScrollButtons";
import FirstDepositLottery from "../components/FirstDepositLottery";
import CorefyRedirectDeposit from "../components/CorefyRedirectDeposit";

export default function DepositCorefy({ isQuickDeposit }) {
    const user = useSelector((state) => state.user.data)
    const bonuses = useSelector((state) => state.bonuses.list);

    const global = useSelector((state) => state.globalContent.data.limits);
    const globalLimits = global.filter(item => {
        return item.currency === user?.currency?.id;
    })[0] || null;

    const step1 = useRef(null);
    const step2 = useRef(null);
    const step3 = useRef(null);

    /** Promocode from URL */
    const { promoCodeURL } = useParams();

    /** Amount to deposit */
    const [amount, setAmount] = useState(45);

    /** Payment method */
    const [paymentMethod, setPaymentMethod] = useState('');

    /** Active bonus promocode */
    const [promoCode, setPromoCode] = useState('');

    /** Variable used to identify if bonus search field is active or not */
    const [promoCodeSearch, setPromoCodeSearch] = useState(false); //(promoCodeURL ? true : false);

    /** Variable to check the first enter */
    const [firstEnter, setFirstEnter] = useState(true);

    /** Variable to toggle bonus dropdown */
    const [showBonus, setShowBonus] = useState(false); //(promoCodeURL ? true : false);

    /** Step */
    const [step, setStep] = useState(1);

    /** Deposit limits */
    const [limits, setLimits] = useState({
        depositMinAmount: globalLimits?.depositMinAmount,
        depositMaxAmount: globalLimits?.depositMaxAmount,
    });

    const applyBonus = (code) => {
        const bonus = bonuses[code];
        if (!bonus || !validateBonus(bonus, user, 'DEPOSIT', true)) {
            if (promoCode !== '') {
                setLimits({
                    depositMinAmount: globalLimits.depositMinAmount,
                    depositMaxAmount: globalLimits.depositMaxAmount,
                })
                setPromoCode('');
            }
            return;
        }

        const option = bonus.currencyList.filter(item => {
            return item.currency.id === user.currency.id;
        })[0];

        setPromoCode(code);
        setLimits({
            depositMinAmount: option.depositMinAmount,
            depositMaxAmount: option.depositMaxAmount,
        })
    }

    const getLimits = (bonus) => {
        const option = bonus.currencyList.filter(item => {
            return item.currency.id === user.currency.id;
        })[0];

        return `Deposit from ${user?.currency?.sign}${option?.depositMinAmount} up to ${user?.currency?.sign}${option?.depositMaxAmount}`;
    }

    const validateSearch = (promoCode) => {
        const bonus = bonuses[promoCode];
        if (!bonus || !validateBonus(bonus, user, 'DEPOSIT', true)) { return false; }
        return true;
    }

    const transitionToStep = (step) => {
        if (step === 1) {
            step2.current.classList.add("animate__fadeOutRight");
            setTimeout(() => {
                setStep(step);
                step1.current.classList.add("animate__fadeIn");
            }, 300);
        }

        if (step === 2) {
            try {
                step1.current.classList.add("animate__fadeOutLeft");
            } catch (error) { }

            try {
                step3.current.classList.add("animate__fadeOutRight");
            } catch (error) { }

            setTimeout(() => {
                setStep(step);
                step2.current.classList.add("animate__fadeIn");
            }, 300);
        }

        if (step === 3) {
            step2.current.classList.add("animate__fadeOutLeft");
            setTimeout(() => {
                setStep(step);
                step3.current.classList.add("animate__fadeIn");
            }, 300);
        }
    }

    const FBDepositInitiated = () => {
        try {
            window.FbEvents.Purchase([
                {
                    id: 0,
                    quantity: 1,
                    item_price: amount
                }
            ], 0).then(data => {
                console.log(data)
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        // console.log(bonuses)
        if (firstEnter && promoCodeURL && Object.entries(bonuses).length > 0) {
            applyBonus(promoCodeURL);
            setFirstEnter(false);

            /** Checking if the URL bonus is a hidden bonus */
            const bonus = bonuses[promoCodeURL];
            if (!validateBonus(bonus, user, 'DEPOSIT') && validateBonus(bonus, user, 'DEPOSIT', true)) {
                setPromoCodeSearch(true);
            }
        }

        if (promoCode === '') {
            const globalLimits = global.filter(item => item.currency === user?.currency?.id)[0] || null;

            if (limits.depositMinAmount !== globalLimits.depositMinAmount) {
                setLimits({
                    depositMinAmount: globalLimits.depositMinAmount,
                    depositMaxAmount: globalLimits.depositMaxAmount,
                })
            }
        }
    })

    return (
        <>
            <Helmet>
                <title>Deposit | Cherry Spins Casino</title>
            </Helmet>

            <section className="deposit-container">
                {step === 1 && (
                    <div ref={step1} className="animate__animated animate__faster">

                        <section className="deposit-section">

                            <FirstDepositLottery isDepositPage={true} />

                            {/* Bonus section */}
                            <BonusSection
                                user={user}
                                bonuses={bonuses}
                                promoCode={promoCode}
                                setPromoCode={setPromoCode}
                                promoCodeSearch={promoCodeSearch}
                                setPromoCodeSearch={setPromoCodeSearch}
                                validateSearch={validateSearch}
                                applyBonus={applyBonus}
                                isQuickDeposit={isQuickDeposit}
                            />

                            {/* Amount section */}
                            <h2 className="subtitle white-100 mb-2">Amount</h2>
                            <div>
                                <div className="preset-amounts">
                                    <Button className="btn-preset-amounts" onClick={() => { setAmount(30); }} active={parseInt(amount) === 30}>{user.currency.sign}30</Button>
                                    <Button className="btn-preset-amounts" onClick={() => { setAmount(45); }} active={parseInt(amount) === 45}>{user.currency.sign}45</Button>
                                    <Button className="btn-preset-amounts" onClick={() => { setAmount(75); }} active={parseInt(amount) === 75}>{user.currency.sign}75</Button>
                                </div>

                                <Form.Group controlId="amount">
                                    <FloatingLabel label={`Deposit amount (${user.currency?.sign})`} >
                                        <Form.Control
                                            type="number"
                                            className="amount-input"
                                            aria-label="Promo code"
                                            value={amount}
                                            onChange={(e) => { setAmount(e.target.value) }}
                                            isValid={amount >= limits.depositMinAmount && amount <= limits.depositMaxAmount}
                                            isInvalid={amount < limits.depositMinAmount || amount > limits.depositMaxAmount}
                                        />

                                        {
                                            promoCode === '' ? (
                                                <Form.Control.Feedback type="invalid" tooltip>
                                                    Deposit amount should be between {user.currency.sign}{limits.depositMinAmount} and {user.currency.sign}{limits.depositMaxAmount}.
                                                </Form.Control.Feedback>
                                            ) : (
                                                <Form.Control.Feedback type="invalid" tooltip>
                                                    Deposit amount for selected bonus should be between {user.currency.sign}{limits.depositMinAmount} and {user.currency.sign}{limits.depositMaxAmount}.
                                                </Form.Control.Feedback>
                                            )
                                        }

                                    </FloatingLabel>
                                </Form.Group>
                            </div>
                            <div className="btn-deposit-container">
                                <div className="btn-deposit-wrapper">
                                    <Button
                                        className="btn-supplementary-lg btn-deposit"
                                        disabled={amount < limits.depositMinAmount || amount > limits.depositMaxAmount}
                                        onClick={() => {
                                            if (amount >= limits.depositMinAmount && amount <= limits.depositMaxAmount) {
                                                !validateBonus(bonuses[promoCode], user, 'DEPOSIT', true) && setPromoCode('');
                                                transitionToStep(2);
                                                voluumSendPostback('dep-start');
                                                //FBDepositInitiated();
                                            }
                                        }}>Deposit
                                    </Button>
                                </div>
                            </div>
                        </section>
                    </div>
                )}

                {step === 2 && (
                    <div ref={step2} className="animate__animated animate__faster">
                        <div className="deposit-section">
                            <ButtonBack transitionToStep={transitionToStep} step={1} copy="Return to deposit amount" />
                            <h4 className="h4 h4-bold mt-2 mb-3">Choose your payment method</h4>
                            <ChoosePaymentMethodCorefy setPaymentMethod={setPaymentMethod} transitionToStep={transitionToStep} isQuickDeposit={isQuickDeposit} amount={amount} />
                        </div>
                    </div>
                )}

                {step === 3 && (
                    <div ref={step3} className="animate__animated animate__faster">
                        <div className="deposit-section">
                            <ButtonBack transitionToStep={transitionToStep} step={2} copy="Return to payment methods" />
                            <h4 className="h4 h4-bold mt-2 mb-3">Complete your payment</h4>
                            <CorefyRedirectDeposit paymentMethod={paymentMethod} amount={amount} promoCode={promoCode} />

                            {/* {
                                {
                                    "card": <PraxisHPFDeposit paymentMethod={paymentMethod} amount={amount} promoCode={promoCode} />,
                                    // "visa": <PraxisHPFDeposit paymentMethod={paymentMethod} amount={amount} promoCode={promoCode} />,
                                    // "mastercard": <PraxisHPFDeposit paymentMethod={paymentMethod} amount={amount} promoCode={promoCode} />,
                                    // "iframe": (
                                    //     <section id="depositIframeSection" className="deposit-iframe-section">
                                    //         <div className="deposit-iframe-wrapper">
                                    //             <PraxisIFrameDeposit amount={amount} promoCode={promoCode} />
                                    //         </div>
                                    //     </section>
                                    // ),
                                    // "interac": <PraxisAPMDeposit paymentMethod={paymentMethod} amount={amount} promoCode={promoCode} />,
                                    // "coinspaid": <PraxisAPMDeposit paymentMethod={paymentMethod} amount={amount} promoCode={promoCode} />,
                                    // "much_better": <PraxisAPMDeposit paymentMethod={paymentMethod} amount={amount} promoCode={promoCode} />,
                                    // "pay_with_click": <PraxisAPMDeposit paymentMethod={paymentMethod} amount={amount} promoCode={promoCode} />,
                                    // "revolut": <PraxisAPMDeposit paymentMethod={paymentMethod} amount={amount} promoCode={promoCode} />,
                                    // "payop": <PraxisAPMDeposit paymentMethod={paymentMethod} amount={amount} promoCode={promoCode} />,
                                    // "paydo_ewallet": <PraxisAPMDeposit paymentMethod={paymentMethod} amount={amount} promoCode={promoCode} />,
                                    // "paydo_cards": <PraxisAPMDeposit paymentMethod={paymentMethod} amount={amount} promoCode={promoCode} />,
                                    // "skrill": <PraxisAPMDeposit paymentMethod={paymentMethod} amount={amount} promoCode={promoCode} />,
                                }[paymentMethod]
                            } */}
                        </div>
                    </div>
                )}

                {/* Payment icons */}
                <div className="deposit-icons-container">
                    <img className="deposit-icons" src={paymentLogos} alt="" />
                </div>

                <DepositVisitEvent />
            </section>
        </>
    );
}

function DepositVisitEvent() {
    useEffect(() => { voluumSendPostback('dep-visit') }, []);
    return null;
}

function ButtonBack({ transitionToStep, step, copy }) {
    return (
        <div>
            <Button className="btn-secondary btn-deposit-back" onClick={() => { transitionToStep(step) }}>
                <FontAwesomeIcon icon={faChevronLeft} /> &nbsp;
                {copy}
            </Button>
        </div>
    )
}

function BonusCard({ bonus, user, selectBonusToggle, isSelected }) {

    const showDepositMinAmount = () => {
        const option = bonus.currencyList.filter(item => {
            return item.currency.id === user.currency.id;
        })[0];

        return `Min. deposit: ${user?.currency?.sign}${option?.depositMinAmount}`;
    }

    return (
        <>
            {/* <div className={`bonus-card ${isSelected && "bonus-card-selected"} animate__animated animate__faster animate__fadeIn`}>
                <div>
                    <img src={bonus?.text?.imageUrl} />
                    <p className="bonus-card-title">{bonus?.text?.title}</p>
                    <p className="mb-0">Minimu deposit C$10 to C$1000</p>
                </div>
                <Button
                    className="btn btn-supplementary btn-bonus-select"
                    onClick={selectBonusToggle}
                >
                    {
                        isSelected ? (
                            <FontAwesomeIcon icon={faCircleCheck} className="icon-bonus-selected animate__animated animate__faster animate__fadeIn" />
                        ) : (
                            <span className="animate__animated animate__faster animate__fadeIn">Select</span>
                        )
                    }
                </Button>
            </div> */}
            <Button
                className={`bonus-card ${isSelected && "bonus-card-selected"} animate__animated animate__faster animate__fadeIn`}
                onClick={selectBonusToggle}
            >
                {
                    isSelected && (
                        <div className="bonus-card-check">
                            <FontAwesomeIcon icon={faCircleCheck} className="white-100" />
                        </div>
                    )
                }
                <img src={bonus?.text?.imageUrl} />
                <div className="bonus-card-text-wrapper">
                    <div>
                        <p className="mb-0 bonus-card-badge" style={{ fontSize: "10px", marginBottom: "3px" }}>{bonus?.text?.badge || 'SPECIAL OFFER'}</p>
                        <p className="bonus-card-title mb-0">{bonus?.text?.title}</p>
                        {/* <p className="bonus-card-title mb-0">100% + 100 Free Spins</p> */}
                    </div>

                    {/* <p className="mb-0">Wager: 20x</p> */}
                    <p className="mb-0 bonus-card-details" style={{ fontSize: "12px" }}>{showDepositMinAmount()}</p>
                </div>
            </Button>
        </>
    )
}

function BonusSection({ user, bonuses, promoCode, setPromoCode, promoCodeSearch, setPromoCodeSearch, validateSearch, applyBonus, isQuickDeposit }) {
    const container = useRef(null);

    // useEffect(() => {
    //     console.log(bonuses)
    // })
    return (
        <>
            <div className="mb-3 mt-0">
                <div className="bonus-section-title-wrapper">
                    <h2 className="subtitle white-100 mb-1">Select your bonus</h2>
                    {
                        validateBonus(bonuses[promoCode], user, 'DEPOSIT', true) && bonuses[promoCode]?.text?.title && (
                            <p className={`mb-0 ${isQuickDeposit && "bonus-section-subtitle"}`}>
                                <FontAwesomeIcon icon={faCircleCheck} style={{ color: '#26c0cd', marginRight: '5px' }} />
                                {bonuses[promoCode]?.text?.title}
                            </p>
                        )
                    }
                    {
                        !validateBonus(bonuses[promoCode], user, 'DEPOSIT', true) && (
                            <p className="mb-0">No bonus selected</p>
                        )
                    }
                </div>


                {/* Existing bonus alert */}
                {/* {user?.bonus?.length > 0 && (
                    <Alert variant="warning" className="mb-3 mt-0 text-center pt-2 pb-2" style={{ fontSize: '14px' }}>
                        Claiming another bonus will cancel your current bonus.
                    </Alert>
                )} */}

                {/* Bonus list */}
                {
                    Object.entries(bonuses).
                        filter(([key, item]) => validateBonus(bonuses[item.promoCode], user, 'DEPOSIT', false)).
                        length > 0 && (
                        <div className="position-relative mb-3">
                            <div className="bonus-card-row-wrapper" ref={container}>
                                {
                                    Object.entries(bonuses).map(([key, bonus]) => {

                                        return !validateBonus(bonus, user, 'DEPOSIT') ? null : (
                                            <BonusCard
                                                key={key}
                                                bonus={bonus}
                                                user={user}
                                                isSelected={promoCode === bonus.promoCode}
                                                selectBonusToggle={() => {
                                                    promoCode !== bonus.promoCode ? applyBonus(bonus.promoCode) : applyBonus('');
                                                    setPromoCodeSearch(false)
                                                }}
                                            />
                                        )
                                    })
                                }
                            </div>
                            <ScrollButtons container={container} scroll={200} />
                        </div>
                    )
                }

                {/* Promocode field */}
                {
                    !isQuickDeposit && (
                        <Form.Group controlId="promoCode">
                            <FloatingLabel label="Enter your promocode">
                                <Form.Control
                                    type="text"
                                    value={promoCodeSearch ? promoCode : ''}
                                    isValid={promoCodeSearch && validateSearch(promoCode)}
                                    onChange={(e) => {
                                        !promoCodeSearch && setPromoCodeSearch(true);
                                        setPromoCode(e.target.value);
                                        validateSearch(e.target.value) && applyBonus(e.target.value);
                                    }} />
                            </FloatingLabel>
                        </Form.Group>
                    )
                }
            </div>
        </>
    )
}