export default function getImageSrc (path) {
    if (!path) return '';

    if (String.prototype.includes.call(path, 'http')) {
        return path.replace(' ', '%20');
    }

    let src = '';
    
    try { 
        src = require(`../data/${path}`).default; 
    } catch (error) {}

    return src;
}