
import React from "react";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import License from "./License";

import imageSecurePayments from "../data/images/secure-payments.png"
import imageAgeLimit from "../data/images/age-limit.png"
import imagePCIDSS from "../data/images/pci-dss-compliant.png"
import imageRapidSSL from "../data/images/rapid-ssl.png"
import imageInterac from "../data/images/interac-white-logo.png"
import award from "../data/images/award.png"

// import './footer.scss';
import logo from '../data/images/logo-white.png'

export default function Footer() {

    const locale = useSelector((state) => state.globalContent.data.locale);

    return <>
        <Container fluid className="footer-container">

            <Container fluid="lg">

                <img src={logo} className="footer-logo" alt="Cherry Spins" />

                <div className="footer-content">

                    <div className="footer-info">
                        {/* <p>
                            Cherryspins.com is operated by Spinsoft Interactive N.V. (Reg no. 160756), Abraham de Veerstraat 9, Willemstad, P.O. Box 3421, Curaçao, which having all the rights to operate the gaming software and/or offer games of chance under the Anjouan License no. ALS l-192407033-Fl2. For any help please contact us at info@cherryspins.support. Play responsibly.
                        </p> */}
                        {/* <p>Crafted with <span className="high-emphasis">❤️</span> in Canada.</p> */}
                        
                        {/* Payments are processed by Ashglade Ltd (ΗΕ 383723) registered in Cyprus, acting as the payments agent as per agreement between the two companies.  */}
                        <p>
                            This statement serves as a formal declaration that the content or intellectual property in question is protected under copyright laws. It signifies that the creator or the rights holder has reserved all the privileges provided by copyright law, such as the right to reproduce, distribute, and adapt the work.
                        </p>
                        <p>
                            Responsible gambling plays a vital role in ensuring a positive and enjoyable experience for participants, promoting safe practices that help individuals manage their activities within their limits, thereby safeguarding against the risks of addiction and its related challenges, while also contributing to the overall well-being of the community by fostering a secure, inclusive, and responsible gaming environment.
                        </p>
                        <p>
                            Copyright © 2024 «Cherry Spins» <br />
                            All rights reserved and protected by law.
                        </p>
                    </div>

                    <div className="footer-links">
                        {/* <Link to={`/${locale}/self-exclusion`} className="footer-link">Self exclusion</Link>
                        <Link to={`/${locale}/dispute-resolution`} className="footer-link">Dispute resolution</Link>
                        <Link to={`/${locale}/terms-and-conditions`} className="footer-link">Terms and conditions</Link>
                        <Link to={`/${locale}/responsible-gambling`} className="footer-link">Responsible gaming</Link>
                        <Link to={`/${locale}/privacy-policy`} className="footer-link">Privacy policy</Link> */}
                        <a href="https://www.cherryspins.support/affiliates" target="_blank" className="footer-link">Affiliates</a>
                        <a href="https://www.cherryspins.support/self-exclusion" target="_blank" className="footer-link">Self exclusion</a>
                        <a href="https://www.cherryspins.support/dispute-resolution" target="_blank" className="footer-link">Dispute resolution</a>
                        <a href="https://www.cherryspins.support/terms-and-conditions" target="_blank" className="footer-link">Terms and conditions</a>
                        <a href="https://www.cherryspins.support/responsible-gaming" target="_blank" className="footer-link">Responsible gaming</a>
                        <a href="https://www.cherryspins.support/privacy-policy" target="_blank" className="footer-link">Privacy policy</a>
                        <a href="https://www.cherryspins.support/bonus-terms" target="_blank" className="footer-link">Bonus terms</a>
                    </div>
                </div>

                <div className="footer-images">
                    {/* <iframe src="https://licensing.gaming-curacao.com/validator/?lh=018fd243acad9e49260232155ca1c04e&template=tseal" width="150" height="50" style={{border: "none"}}></iframe> */}
                    
                    {/* <img src="https://cherryspins.b-cdn.net/assets/gc-logo.png" className="footer-image" alt="" /> */}
                    {/* <License /> */}
                    <img src={imageInterac} className="footer-image footer-image-opacity" alt="" />
                    {/* <img src={award} className="footer-image footer-image-opacity" alt="" height="70px"/> */}
                    <img src="https://cherryspins.b-cdn.net/images/award.png" className="footer-image footer-image-opacity" alt="" height="70px" />
                    <img src={imageSecurePayments} className="footer-image footer-image-opacity" alt="" />
                    <img src={imagePCIDSS} className="footer-image footer-image-opacity" alt="" />
                    <img src={imageAgeLimit} className="footer-image footer-image-opacity" alt="" />
                    <img src={imageRapidSSL} className="footer-image footer-image-opacity" alt="" />
                </div>
            </Container>
        </Container>
    </>
};