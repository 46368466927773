import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';

import Fallback from './components/Fallback';
import Login from './pages/Login';
import Register from './pages/Register';
import RegisterNew from './pages/RegisterNew';
import ForgotPassword from './pages/ForgotPassword';
import QuickDeposit from './pages/QuickDeposit';
import Games from './pages/Games';
import Details from "./pages/Details";
import Deposit from "./pages/Deposit";
import DepositNew from './pages/DepositNew';
import DepositCorefy from './pages/DepositCorefy';
import Withdraw from "./pages/Withdraw";
import WithdrawNew from "./pages/WithdrawNew";
import History from "./pages/History";

const Home = React.lazy(() => import("./pages/Home"));
const Game = React.lazy(() => import("./pages/Game"));
const Profile = React.lazy(() => import("./pages/Profile"));
const ResetPassword = React.lazy(() => import("./pages/ResetPassword"));
// const Promocode = React.lazy(() => import("./pages/Promocode"));
const SelfExclusion = React.lazy(() => import("./pages/SelfExclusion"));
const DisputeResolution = React.lazy(() => import("./pages/DisputeResolution"));
const TermsAndConditions = React.lazy(() => import("./pages/TermsAndConditions"));
const ResponsibleGambling = React.lazy(() => import("./pages/ResponsibleGambling"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
const RegionBlocked = React.lazy(() => import("./pages/RegionBlocked"));


export default function RenderRoutes() {
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const location = useLocation();
    const locale = useSelector((state) => state.globalContent.data.locale);

    // const localeFromURL = location.pathname.split('/')[1];
    const urlParams = location.search.split('?')[1] ? location.search.split('?')[1].split('&')[0] : '';

    /** Routes that can be called via "?" */
    const parameterRoutes = () => {
        switch (urlParams) {
            case 'login':
                return <Login />
            case 'register':
                return !userLoggedIn ? <RegisterNew /> : <Navigate to={`/${locale}`} replace />
            case 'quick-deposit':
                return userLoggedIn ? <QuickDeposit /> : <Navigate to={`/${locale}?login`} state={{ forwardLocation: location }} replace />
            case 'forgot-password':
                return <ForgotPassword />
            default:
                return null;
        }
    }

    useEffect(() => { })

    // if (locale !== localeFromURL) {
    //     return <Navigate to={`/${locale}`} replace />
    // }

    return <>
        <Routes>
            <Route index path='/:locale'
                element={
                    <React.Suspense fallback={<Fallback />}>
                        <Home />
                    </React.Suspense>
                }
            />
            <Route index path='/:locale/reset-password'
                element={
                    userLoggedIn ? <Navigate to={`/${locale}?login`} state={{ forwardLocation: location }} replace /> :
                        <React.Suspense fallback={<Fallback />}>
                            <ResetPassword />
                        </React.Suspense>
                }
            />
            <Route path="/:locale/games"
                element={
                    <Navigate to={`/${locale}/games/lobby`} state={{ ...location.state }} replace />
                }
            />
            <Route path="/:locale/games/:categoryURL"
                element={
                    <Games />
                }
            />
            <Route path="/:locale/games/:categoryURL/:subcategoryURL"
                element={
                    <Games />
                }
            />
            <Route path="/:locale/game/:id"
                element={
                    !userLoggedIn ? <Navigate to={`/${locale}?register&game=${location?.pathname?.split('game/')[1]?.split('&')[0]}`} state={{ forwardLocation: location }} replace /> :
                        <React.Suspense fallback={<Fallback />}>
                            <Game />
                        </React.Suspense>
                }
            />
            {/* <Route path="/:locale/promocode"
                element={
                    !userLoggedIn ? <Navigate to={`/${locale}?register`} state={{ forwardLocation: location }} replace /> :
                        <React.Suspense fallback={<Fallback />}>
                            <Promocode />
                        </React.Suspense>
                }
            />
            <Route path="/:locale/promocode/:promoCode"
                element={
                    !userLoggedIn ? <Navigate to={`/${locale}?register`} state={{ forwardLocation: location }} replace /> :
                    <React.Suspense fallback={<Fallback />}>
                        <Promocode />
                    </React.Suspense>
                }
            /> */}
            <Route path="/:locale/region-blocked"
                element={
                    <React.Suspense fallback={<Fallback />}>
                        <RegionBlocked />
                    </React.Suspense>
                }
            />
            <Route path="/:locale/self-exclusion"
                element={
                    <React.Suspense fallback={<Fallback />}>
                        <SelfExclusion />
                    </React.Suspense>
                }
            />
            <Route path="/:locale/dispute-resolution"
                element={
                    <React.Suspense fallback={<Fallback />}>
                        <DisputeResolution />
                    </React.Suspense>
                }
            />
            <Route path="/:locale/terms-and-conditions"
                element={
                    <React.Suspense fallback={<Fallback />}>
                        <TermsAndConditions />
                    </React.Suspense>
                }
            />
            <Route path="/:locale/responsible-gambling"
                element={
                    <React.Suspense fallback={<Fallback />}>
                        <ResponsibleGambling />
                    </React.Suspense>
                }
            />
            <Route path="/:locale/privacy-policy"
                element={
                    <React.Suspense fallback={<Fallback />}>
                        <PrivacyPolicy />
                    </React.Suspense>
                }
            />
            <Route path="/:locale/profile"
                element={
                    !userLoggedIn ? <Navigate to={`/${locale}?login`} state={{ forwardLocation: location }} replace /> :
                        <React.Suspense fallback={<Fallback />}>
                            <Profile />
                        </React.Suspense>
                }>
                <Route index element={
                    <Details />
                } />
                <Route path="deposit"
                    element={
                        <DepositCorefy />
                    } />
                <Route path="deposit-corefy"
                    element={
                        <DepositCorefy />
                    } />
                {/* <Route path="deposit-new"
                    element={
                        <DepositNew />
                    } /> */}
                <Route path="deposit/:promoCodeURL"
                    element={
                        <DepositCorefy />
                    } />
                <Route path="withdraw"
                    element={
                        <WithdrawNew />
                    } />
                <Route path="withdraw-corefy"
                    element={
                        <WithdrawNew />
                    } />
                {/* <Route path="history"
                    element={
                        <History />
                    } /> */}
                <Route path="*"
                    element={
                        <React.Suspense fallback={<Fallback />}>
                            <Details />
                        </React.Suspense>
                    } />
            </Route>
            <Route path="*" element={<Navigate to={`/${locale}`} />} />
        </Routes>

        {parameterRoutes()}
    </>
}