
import React, { useEffect, useState } from "react";
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'

export default function ScrollButtons({ container, scroll }) {
    const [maxScrollPosition, setMaxScrollPosition] = useState(-1)
    const [visibleArrowLeft, setVisibleArrowLeft] = useState(false);
    const [visibleArrowRight, setVisibleArrowRight] = useState(true);

    const adjustScrollPosition = (value) => {
        let tempScrollPosition = container.current.scrollLeft + value;

        if (tempScrollPosition < 0) tempScrollPosition = 0;
        if (tempScrollPosition > maxScrollPosition) tempScrollPosition = maxScrollPosition;

        container.current.scrollTo({
            left: tempScrollPosition,
            behavior: 'smooth'
        });
    }

    const arrowHandler = (e) => {
        if (visibleArrowLeft && e.target.scrollLeft <= 0) {
            setVisibleArrowLeft(false)
        }
        else if (!visibleArrowLeft) {
            setVisibleArrowLeft(true)
        }

        if (visibleArrowRight && e.target.scrollLeft >= maxScrollPosition) {
            setVisibleArrowRight(false)
        }
        else if (!visibleArrowRight) {
            setVisibleArrowRight(true)
        }
    }

    useEffect(() => {
        const containerRef = container.current;
        containerRef && containerRef.addEventListener("scroll", arrowHandler);

        if (containerRef && (containerRef.scrollWidth - containerRef.clientWidth) !== maxScrollPosition) {
            setMaxScrollPosition(containerRef.scrollWidth - containerRef.clientWidth);
        }

        return () => {
            containerRef && containerRef.removeEventListener("scroll", arrowHandler);
        }

    });

    return (
        <>
            <Button
                className={`arrow-left ${maxScrollPosition !== 0 && 'arrow-visible'} ${visibleArrowLeft && 'arrow-active'}`}
                onClick={() => adjustScrollPosition(-(scroll || 600) )}>
                <FontAwesomeIcon icon={faChevronLeft} />
            </Button>

            <Button
                className={`arrow-right ${maxScrollPosition !== 0 && 'arrow-visible'} ${visibleArrowRight && 'arrow-active'}`}
                onClick={() => adjustScrollPosition(scroll || 600)}>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>
        </>
    );
}