import { createSlice } from "@reduxjs/toolkit";
import { dbGetBonuses } from '../utils/db-functions'

export const bonuses = createSlice({
    name: "bonuses",
    initialState: {
        list: {}
    },
    reducers: {
        updateBonuses: (state, action) => {
            /********************/
            /** Updating the store */
            let newBonusList = {};

            action.payload.list.forEach(bonus => {
                newBonusList[bonus.promoCode] = bonus;
            });

            Object.assign(state, {
                ...state, ...{
                    list: newBonusList,
                }
            });
        },
        overrideDepositMinAmountBonus: (state, action) => {

            /** Copy all the bonuses to a new object */
            let newBonuses = Object.assign({}, action.payload.bonuses);

            /** Go through each bonus in the object */
            for (let [key, value] of Object.entries(action.payload.bonuses)) {
                
                if (value.activationType === 'DEPOSIT') {
                    /** Create a temporary copy of the bonus */
                    let tempBonus = Object.assign({}, value);

                    /** Create an empty array or currencies */
                    let tempCurrencyList = [];
                    
                    /** Go through each currency option and and check the minimum deposit amount */
                    for (let option of tempBonus?.currencyList) {
                        if (option.depositMinAmount < action.payload.depositMinAmount) {
                            
                            /** Create a temporary option copy */
                            let tempOption = Object.assign({}, option);

                            /** Update the minimum deposit */
                            tempOption.depositMinAmount = action.payload.depositMinAmount;

                            /** Add option to the new currency list */
                            tempCurrencyList.push(tempOption);
                        }

                        tempCurrencyList.push(option);
                    }

                    /** Add options to temporary bonus */
                    tempBonus.currencyList = tempCurrencyList;

                    /** Override the bonus */
                    newBonuses[tempBonus.promoCode] = tempBonus;
                }
            }

            Object.assign(state, { list: newBonuses });
        }
    }
});

export const GetBonusesAsync = (country, language) => (dispatch) => {

    dbGetBonuses(country, language)
        .then(res => {

            if (res.data.docs) {

                dispatch(updateBonuses({ list: res.data.docs }));

            }
        })
        .catch(err => { })
}

// Action creators are generated for each case reducer function
export const {
    updateBonuses,
    overrideDepositMinAmountBonus
} = bonuses.actions;

export default bonuses.reducer;