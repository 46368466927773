import React from "react";
import { Button } from 'react-bootstrap';

import './loadMore.scss'

export default function LoadMore({ isVisible, ...otherProps }) {
  return (
    <div className="load-more-container">
      {
        isVisible && (
          <Button className="btn btn-secondary btn-load-more" {...otherProps} >Load more</Button>
        )
      }

      {
        !isVisible && (
          <div className="text-center">
            <p>
              Didn't find what you were looking for?
              <br />
              <b>Let us now!</b>
            </p>
          </div>
        )
      }
    </div>
  );
}