import React, { useEffect } from "react";
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import uniqid from 'uniqid';

import GameTile from "./GameTile";

export default function GamesList({ categoryURL }) {
    const placeholder = new Array(12).fill(null);
    const country = useSelector((state) => state.globalContent.data.country)
    const categories = useSelector((state) => state.gameCategories.list);
    const category = categories ? (categories[`${categoryURL}-${country.ISOcode}`] || categories[categoryURL]) : null;

    useEffect(() => {})

    return (
        <Container fluid="lg" className={`game-category-container`}>
            <h3 className="h3">{category ? category.title : 'Loading...'}</h3>

            <div className={`game-list-wrapper mt-3`}>
                {
                    !category ? placeholder.map((game, i) => <GameTile game={game} key={uniqid()} />) :
                        category.games.map(game => <GameTile game={game} key={uniqid()} />)
                }
            </div>
        </Container>
    );
}