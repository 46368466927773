import { createSlice } from "@reduxjs/toolkit";
import { dbGetGameCategories } from '../utils/db-functions'

export const gameCategories = createSlice({
    name: "gameCategories",
    initialState: {
        list: null
    },
    reducers: {
        updateGameCategories: (state, action) => {
            let newCategoryList = {};
            
            action.payload.list.forEach(category => {
                
                let newGames = [];
                let newCategories = [];

                if (category.games) {

                    category.games.forEach(item => {

                        if (item.game?.isActive === true) {

                            newGames.push(item.game);

                        }
                        
                    });

                    category.games = [...newGames];
                }

                if (category.categories) {
                    category.categories.forEach(item => {

                        if (item.category) {
                            newCategories.push(item.category)
                        }

                    });

                    category.categories = [...newCategories];
                }
                
                newCategoryList[category.categoryId] = category;
            });

            //console.log(newCategoryList);

            Object.assign(state, {
                ...state, ...{
                    list: newCategoryList
                }
            });

            // console.log('state.list');
            //console.log(state.list);
            // console.log('game categories update');
        }
    }
});

export const GetGameCategoriesAsync = (country, language) => (dispatch) => {

    dbGetGameCategories(country, language)
        .then(res => {

            if (res.data.docs) {

                dispatch(updateGameCategories({ list: res.data.docs }));

            }
        })
        .catch(err => {
            console.log(err)
        })
}

// Action creators are generated for each case reducer function
export const {
    updateGameCategories
} = gameCategories.actions;

export default gameCategories.reducer;