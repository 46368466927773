
import React, { useEffect, useState } from "react";
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'

export default function ScrollButtonsSubcategories({ container, subcategoryURL }) {
    const [maxScrollPosition, setMaxScrollPosition] = useState(-1)
    const [isFirstEnter, setIsFirstEnter] = useState(true)
    const [visibleArrowLeft, setVisibleArrowLeft] = useState(false);
    const [visibleArrowRight, setVisibleArrowRight] = useState(false);

    const adjustScrollPosition = (value) => {
        let tempScrollPosition = container.current.scrollLeft + value;

        if (tempScrollPosition < 0) tempScrollPosition = 0;
        if (tempScrollPosition > maxScrollPosition) tempScrollPosition = maxScrollPosition;

        container.current.scrollTo({
            left: tempScrollPosition,
            behavior: 'smooth'
        });
    }

    const sideScrollHandler = (e) => {
        if (e.target.scrollLeft <= 0) {
            setVisibleArrowLeft(false)
        }
        else if (!visibleArrowLeft) {
            setVisibleArrowLeft(true)
        }

        if (e.target.scrollLeft >= maxScrollPosition) {
            setVisibleArrowRight(false)
        }
        else if (!visibleArrowRight) {
            setVisibleArrowRight(true)
        }
    }

    function isInViewport(el) {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    useEffect(() => {
        const containerRef = container.current;
        const activeCategory = document.querySelector("[id='" + subcategoryURL.replace(/ /g, '') + "']");

        /** When containers have loaded */
        if (containerRef && maxScrollPosition !== -1) {

            /** Add event listener for scroll event */
            containerRef.addEventListener("scroll", sideScrollHandler);

            /** Scroll to active category if it is out of sight */
            if (activeCategory?.offsetLeft && !isInViewport(activeCategory) && isFirstEnter) {
                container.current.scrollLeft = activeCategory.offsetLeft - 60;
            }

            /** Show and hide arrows on first enter */
            if (isFirstEnter) {
                if (containerRef.scrollLeft <= 0) {
                    setVisibleArrowLeft(false)
                }
                else if (!visibleArrowLeft) {
                    setVisibleArrowLeft(true)
                }

                if (containerRef.scrollLeft >= maxScrollPosition) {
                    setVisibleArrowRight(false)
                }
                else if (!visibleArrowRight) {
                    setVisibleArrowRight(true)
                }
            }

            /** Cancel first enter */
            isFirstEnter && setIsFirstEnter(false);
        }

        /** Setting max scroll position */
        if (containerRef && (containerRef.scrollWidth - containerRef.clientWidth) !== maxScrollPosition) {
            setMaxScrollPosition(containerRef.scrollWidth - containerRef.clientWidth - 1);
        }

        return () => {
            if (containerRef) {
                containerRef.removeEventListener("scroll", sideScrollHandler);
            }
        }
    });

    return (
        <>
            <Button
                className={`arrow-left-theme ${visibleArrowLeft && 'arrow-visible-theme'}`}
                onClick={() => adjustScrollPosition(-500)}>
                <FontAwesomeIcon icon={faChevronLeft} />
            </Button>

            <Button
                className={`arrow-right-theme ${visibleArrowRight && maxScrollPosition !== 0 && 'arrow-visible-theme'}`}
                onClick={() => adjustScrollPosition(500)}>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>
        </>
    );
}