import { useEffect, useState } from "react";
import { useLocation, useNavigationType } from "react-router-dom";

export default function ScrollRestoration() {

    /** Current page url */
    const { pathname } = useLocation();

    /** Type of history even */
    const navType = useNavigationType();

    /** Array of previous positions */
    const [prevScrollPositionArray, setPrevScrollPositionArray] = useState([])

    /** Current scroll position */
    const [scrollY, setScrollY] = useState(0)

    /** Scroll handler */
    const handlerScrollY = (e) => {
        setScrollY(window.scrollY);
    }

    useEffect(() => {

        /** Getting history object */
        const history = window.history;

        /** Updating it to manual */
        if (history.scrollRestoration) {
            history.scrollRestoration = 'manual';
        }

        /** If return is hit, return initial scroll position */
        if (navType === 'POP') {
            setTimeout(() => {

                /** Check if last scroll position is reachable */
                if ((document.body.scrollHeight - window.innerHeight) > prevScrollPositionArray[prevScrollPositionArray.length - 1]) {
                    /** Restore last scroll position */
                    window.scrollTo(0, prevScrollPositionArray[prevScrollPositionArray.length - 1]);
                }
            }, 500);
        }
        else {
            /** Scroll to top */
            setTimeout(() => {  window.scrollTo(0, 0); }, 0);
        }

        /** Add event listener to update scroll position */
        document.addEventListener('scroll', handlerScrollY);

        //window.history.replaceState({ test: [...prevScrollPositionArray, scrollY] }, 'scrollPosition')

        /** Add last scroll position to array */
        setPrevScrollPositionArray([...prevScrollPositionArray, scrollY]);

        return () => {

            /** remove event listener */
            document.removeEventListener('scroll', handlerScrollY);
        }

    }, [pathname]);

    return null;
}