import React, {useEffect} from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

export default function NavLink({ children, to, ...props }) {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: props.end ? true : false });

    useEffect(() => { })
  
    return (
        <Link
          id={props.id}
          to={to}
          className={`${props.className} ${match ? props.activeClassName : ''}`}
          onClick={props.onClick}
          key={props.key}
        >
          {children}
        </Link>
    );
  }