import React, {useRef, useEffect} from "react";
import { useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { dbLogout } from '../utils/db-functions';
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet-async";

import PlayerInformationForm from "../components/PlayerInformationForm";

//import './details.scss'

export default function Details() {
    const user = useSelector((state) => state.user.data);
    const navigate = useNavigate();

    const wagerBar = useRef(null);
    const wagerBarFill = useRef(null);

    const logout = () => {
        dbLogout().then(() => { navigate(0) });
    };

    const roundNumber = (num) => {
        return Math.round((num + Number.EPSILON) * 100) / 100;
    }

    const wagerPercentCalculate = () => {
        let percent = (user.bonus[0].betsMade * 100) / user.bonus[0].betsTarget;
        percent = isNaN(percent) ? 0 : percent;
        
        return percent > 100 ? 100 : roundNumber(percent);
    }

    const wagerBarFillCalculate = () => {
        let width = wagerBar.current.offsetWidth * (user.bonus[0].betsMade / user.bonus[0].betsTarget);
        width = isNaN(width) ? 0 : width;

        return width > wagerBar.current.offsetWidth ? wagerBar.current.offsetWidth : width;
    }

    const cancelBonusHandler = () => {

    }

    useEffect(() => {
        if (user?.bonus?.length > 0) {
            wagerBarFill.current.style.width = wagerBarFillCalculate() + 'px';
        }
    })

    return (
        <>
            <Helmet>
                <title>Personal details | Cherry Spins Casino</title>
            </Helmet>
            <section className="profile-section">
                {/* <h2 className="h4 h4-bold mb-0 personalized">Total balance: {user.currency.sign}{roundNumber(totalBalance(user) || 0)}</h2> */}
                <div className="profile-total-balance">
                    <h1 className="title-xs white-100 profile-total-balance-name">{`${user.firstName} ${user.lastName}`}</h1>
                    <div>
                        <span className="title-xs white-100">{user.currency.sign}{roundNumber(totalBalance(user) || 0)}</span>
                    </div>
                </div>
                
                {
                    user.bonus[0] && (
                        <div className="bonus-details">
                            <p className="mb-1">Active bonus:</p>
                            <div className="profile-total-balance mb-2">
                                <p className="mb-0 subtitle white-100 profile-total-balance-promocode">{user.bonus[0]?.promoCode}</p>
                                <div>
                                    <span className="subtitle white-100">{user.currency.sign}{roundNumber(bonusBalance(user) || 0)}</span>
                                </div>
                            </div>
                            <p className="mb-2">Wager progress:</p>
                            <div className="profile-wager-bar mb-2" ref={wagerBar} >
                                <div className="profile-wager-bar-numbers white-100">
                                    <span>{`${wagerPercentCalculate()}% / 100%`}</span>
                                </div>
                                <div className="profile-wager-bar-fill" ref={wagerBarFill}></div>
                            </div>

                            <div className="profile-cancel-bonus">
                                <p className="mb-0 mt-0">
                                    <span className="disabled-text">Bonus will expire on {new Date(user.bonus[0].activeUntil).toDateString()}.</span>
                                </p>
                                {/* <div>
                                    <Button onClick={cancelBonusHandler} className="btn-secondary btn-cancel-bonus mt-1 mb-1">Cancel bonus</Button>
                                </div> */}
                            </div>
                        </div>
                    )
                }
            </section>

            <section className="profile-section">
                <h4 className="h4 h4-bold mb-2">Personal details</h4>
                <PlayerInformationForm />
            </section>

            <section className="profile-section">
                <Button className="btn-main" onClick={logout}>Log out</Button>
            </section>
        </>
    );
}

function bonusBalance(user) {
    const bonus = parseFloat(user.bonus[0]?.bonusDepositBalance || 0) + parseFloat(user.bonus[0]?.bonusDepositWinnings || 0) + parseFloat(user.bonus[0]?.bonusFreeBalance || 0) + parseFloat(user.bonus[0]?.bonusFreeWinnings || 0);
    return bonus;
}

function totalBalance(user) {
    const real = parseFloat(user.wagerBalance) + parseFloat(user.withdrawableBalance);
    const bonus = parseFloat(user.bonus[0]?.bonusDepositBalance || 0) + parseFloat(user.bonus[0]?.bonusDepositWinnings || 0) + parseFloat(user.bonus[0]?.bonusFreeBalance || 0) + parseFloat(user.bonus[0]?.bonusFreeWinnings || 0);
    return parseFloat(real) + parseFloat(bonus);
}

function CancelBonusModal({show, handleClose, }) {
    const cancelBonusHandler = () => {}

    useEffect(() => {}, [])


    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Session timed out</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>You have been idle. Please, login again.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn-main" onClick={() => { cancelBonusHandler() }}>
                    Login
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
