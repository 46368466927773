import React, { useEffect, useState } from "react";
import { Container, Placeholder } from 'react-bootstrap';
import { dbGetGamesByProviderName } from "../utils/db-functions";
import uniqid from 'uniqid';

import GameTile from "./GameTile";

export default function ProvidersList({ name }) {
    const placeholder = new Array(12).fill(null);
    const [provider, setProvider] = useState({
        name: '',
        games: []
    });

    useEffect(() => {
        provider.name !== name && dbGetGamesByProviderName(name)
            .then(res => {
                if (res.data.docs[0]) {
                    setProvider({
                        name: name,
                        games: res.data.docs
                    });
                }
            });

        provider.name !== name && setProvider({
            ...provider, ...{ games: [], name: '' }
        })
    }, [name])

    return (
        <Container fluid="lg" className={`game-category-container`}>
            <h3 className="h3">{provider.name ? provider.name :
                <Placeholder as="p" animation="glow" className='mb-0'>
                    <Placeholder xs={2} size="lg" />
                </Placeholder>}
            </h3>

            <div className={`game-list-wrapper mt-3`}>
                {
                    provider.games.length === 0 ? placeholder.map((game, i) => <GameTile game={game} key={uniqid()} />) :
                        provider.games.map(game => {
                            if (game) {
                                return <GameTile game={game} key={uniqid()} />
                            }
                            return null;
                        })
                }
            </div>
        </Container>
    );
}