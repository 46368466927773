
import React, { useState, useEffect } from "react";
import { Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import arrow from '../data/images/arrow-lottery.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesDown } from '@fortawesome/free-solid-svg-icons';

export default function FirstDepositLottery({ isDepositPage }) {
    const user = useSelector((state) => state.user.data);

    return (
        <>
            {
                user.depositsMade === 0 && (
                    <>
                        {
                            isDepositPage ? (
                                <Container fluid="lg" className="first_deposit_lottery-deposit animate__animated animate__faster animate__fadeIn">
                                    <div className="first_deposit_lottery-container first_deposit_lottery-deposit-container">
                                        <img src={arrow} className="first_deposit_lottery-arrow"/>
                                        <div className={isMobile ? 'first_deposit_lottery-deposit-content-mobile' : 'first_deposit_lottery-deposit-content'}>

                                            {
                                                isMobile ? (
                                                    <img className="first_deposit_lottery-deposit-image-mobile" alt="" src="https://cherryspins.b-cdn.net/bonuses/ftd-lottery-beach-1.png"/>
                                                ) : (
                                                    <img className="first_deposit_lottery-deposit-image" alt="" src="https://cherryspins.b-cdn.net/bonuses/ftd-lottery-beach-2.png"/>
                                                )
                                            }

                                            <div className="first_deposit_lottery-deposit-text-wrapper">
                                                {/* <p className="first_deposit_lottery-badge">LIMITED TIME OFFER</p> */}
                                                <p className="mb-3 white-87">Complete your deposit for a chance to win a <span className="personalized-gold">7&nbsp;day vacation in the Caribbean</span>.</p>
                                                <LotteryDropTimer />
                                                {/* <div className="first_deposit_lottery-deposit-guide">
                                                    
                                                    <span className="white-60">&nbsp;<FontAwesomeIcon icon={faAnglesDown} /></span>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </Container>
                            ) : (
                                <Container fluid="lg" className="section-margin animate__animated animate__faster animate__fadeIn">
                                    <div className="first_deposit_lottery-container">
                                        <div className="first_deposit_lottery-content">

                                            <div className="first_deposit_lottery-image-wrapper">
                                                <img src="https://cherryspins.b-cdn.net/bonuses/ftd-lottery-beach-1.png" className="first_deposit_lottery-image" alt="" />
                                            </div>
                                            <div className="first_deposit_lottery-text-wrapper">
                                                <p className="first_deposit_lottery-badge mb-1">GET YOURSELF A PIÑA COLADA</p>
                                                {/* <h2 class="subtitle white-100 mb-1">Win a Caribean paradize</h2> */}
                                                <p className="subtitle-xs mb-2 white-87">Complete your first deposit for a chance to win a <span className="personalized-gold">7 day vacation in the Caribbean</span>.</p>
                                                <LotteryDropTimer />
                                            </div>
                                        </div>
                                        <div className="first_deposit_lottery-buttons">
                                            <Link to="?quick-deposit" className="btn-gold mt-1 mb-1 ms-2">Enter the draw</Link>
                                        </div>
                                    </div>
                                </Container>
                            )
                        }
                    </>
                )
            }
        </>
    )
}

function LotteryDropTimer() {
    const [timer, setTimer] = useState('');

    function leadingZero(num) {
        num = num.toString();
        while (num.length < 2) num = "0" + num;
        return num;
    }

    function getTime() {
        const today = new Date();
        let hours = today.getHours();
        let mimuntes = today.getMinutes();
        let seconds = today.getSeconds();
        let secondsFromStartOfDate = (hours * 60 * 60) + (mimuntes * 60) + seconds;
        let secondsUntilEndOfDate = (24 * 60 * 60) - secondsFromStartOfDate;

        let tempHours = Math.floor((secondsUntilEndOfDate / 60 / 60) % 24);
        let tempMinutes = Math.floor((secondsUntilEndOfDate / 60) % 60);
        let tempSeconds = Math.floor(secondsUntilEndOfDate % 60);

        return `${leadingZero(tempHours)}:${leadingZero(tempMinutes)}:${leadingZero(tempSeconds)}`;
    }

    useEffect(() => {
        const time = getTime();
        !timer && setTimer(time);

        const timeout = setTimeout(() => {
            const time = getTime();
            setTimer(time);
        }, 1000);

        return () => {
            clearTimeout(timeout);
        }

    }, [timer])

    return (
        <p className="mb-0 first_deposit_lottery-timer-wrapper">Time left till draw: <span className="first_deposit_lottery-timer">{timer}</span></p>
    )
}