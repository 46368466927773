
import React, { useEffect, useRef } from "react";
import { Container, Placeholder } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import uniqid from 'uniqid';

import GameTile from "./GameTile";
import ScrollButtons from './ScrollButtons';
import MoreGamesLink from "./MoreGamesLink";
import CategoryTitle from "./CategoryTitle";

export default function GamesRow({ categoryURL, moreGames, personalized }) {
    const placeholder = new Array(10).fill(null);
    const country = useSelector((state) => state.globalContent.data.country)
    const categories = useSelector((state) => state.gameCategories.list);
    const category = categories ? (categories[`${categoryURL}-${country.ISOcode}`] || categories[categoryURL]) : null;
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const user = useSelector((state) => state.user.data);

    const container = useRef(null);

    useEffect(() => { });
    return (
        <Container fluid="lg" className="game-category-container game-row-container section-margin" id={categoryURL}>

            {/* Title */}            
            {
                personalized && userLoggedIn ? (
                    <CategoryTitle title={`Hot picks for ${user?.firstName}`} className='personalized-gold' />
                ) : (
                    <CategoryTitle title={category?.title} />
                )
            }

            {/* Row */}
            <div className="position-relative">
                <div className="game-row-wrapper" ref={container}>

                    {/* Games */}
                    {
                        !category ? placeholder.map(game => <GameTile game={game} key={uniqid()} />) :
                            category.games.map((game, i) => {
                                return <GameTile game={game} key={`${categoryURL}-${game.id}-${i}`} />
                            })
                    }

                    {/* {
                        placeholder.map(game => <GameTile game={game} key={uniqid()} />)
                    } */}

                    {/* More games link */}
                    {moreGames && <MoreGamesLink />}

                </div>

                {/* Scroll */}
                <ScrollButtons container={container} />
            </div>
        </Container>
    );
}