import React, { useEffect, useState, useRef, useCallback } from "react";
import { dbCorefyWithdraw } from '../utils/db-functions';
import { DropdownButton, Dropdown, Button, Spinner, FloatingLabel, Form, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle, faChevronRight, faCreditCard, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import Loading from '../components/Loading';
import WithdrawSummary from "./WithdrawSummary";

import 'dotenv/config';

export default function WithdrawRedirect({ amount, paymentMethod }) {
    const user = useSelector((state) => state.user.data)

    const [firstName, setFirstName] = useState(user.firstName || '');
    const [lastName, setLastName] = useState(user.lastName || '');
    const [email, setEmail] = useState(user.email || '');
    const [phone, setPhone] = useState(user.phone || '');
    const [street, setStreet] = useState(user.street || '');
    const [city, setCity] = useState(user.city || '');
    const [state, setState] = useState(user.state || '');
    const [country, setCountry] = useState(user?.country?.ISOcode?.toUpperCase() || '');
    const [zip, setZip] = useState(user.zip || '');
    const [expireYear, setExpireYear] = useState('');
    const [expireMonth, setExpireMonth] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    

    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState('');
    const [isRequestComplete, setIsRequestComplete] = useState(false);

    /** Deposit button */
    const formSubmitHandler = async () => {
        setIsProcessing(true);

        const data = {
            service: paymentMethod,
            currency: user?.currency?.code,
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone: phone,
            street: street,
            city: city,
            state: state,
            zip: zip,
            country: country,
            countryISOcode: user?.country?.ISOcode,
            amount: amount,
            return_url: window.location.origin,
            exp_year: expireYear,
            exp_month: expireMonth,
            card_number: cardNumber,
            card_holder: `${firstName} ${lastName}`
            // TO DO: add card fields and stuff
        }

        let result;

        try {
            result = await dbCorefyWithdraw(data);

            console.log(result?.data)

            if (result?.data?.status === 'created') {
                /** Combine parameters if applicable */
                return setIsRequestComplete(true);
            }

            /** TO DO: check where the error message coming from */
            setIsProcessing(false);
            console.log(result?.data?.errors)
            setError((result?.data?.errors?.length > 0 ? result?.data?.errors[0]?.title : 'Something went wrong.') + ' Please try again or choose a different payment method.');
            setTimeout(() => { setError('') }, 10000)
        } catch (error) {
            console.log(error)
            setIsProcessing(false);
            setError((error?.data?.description || 'Oops, something went wrong') + '. Please try again or choose a different payment method.');
            setTimeout(() => { setError('') }, 10000);
        }
    }

    return (
        <>
            {/* Initial screen */}
            {
                !isRequestComplete ? (
                    <>
                        <WithdrawSummary sign={user?.currency?.sign} amount={amount} />
                        <div className="hpf-container animate__animated animate__faster animate__fadeIn" style={{ minHeight: 'auto' }}>
                            {
                                error && (
                                    <div className="hpf-error-wrapper mb-3 animate__animated animate__faster animate__fadeIn">
                                        <div>
                                            <FontAwesomeIcon icon={faExclamationCircle} className="hpf-error-icon" />
                                        </div>
                                        <p className="hpf-error mb-0 white-100">{error}</p>
                                    </div>
                                )
                            }

                            <div className={`hpf-form-wrapper animate__animated animate__faster animate__fadeIn`}>
                                {
                                    {
                                        "payment_card_eur": (
                                            <CardFields {
                                                ...{
                                                    firstName,
                                                    setFirstName,
                                                    lastName,
                                                    setLastName,
                                                    expireYear,
                                                    setExpireYear,
                                                    expireMonth,
                                                    setExpireMonth,
                                                    cardNumber,
                                                    setCardNumber
                                                }}
                                            />
                                        ),
                                        "interac_etransfer_cad": (
                                            <InteracFields {
                                                ...{
                                                    firstName,
                                                    setFirstName,
                                                    lastName,
                                                    setLastName,
                                                    email,
                                                    setEmail,
                                                    phone,
                                                    setPhone
                                                }}
                                            />
                                        ),
                                        // "coinspaid": (
                                        //     <CoinspaidFields
                                        //         email={email}
                                        //         setEmail={setEmail}
                                        //     />
                                        // ),
                                        // "much_better": (
                                        //     <MuchBetterFields
                                        //         country={country}
                                        //         setCountry={setCountry}
                                        //         phone={phone}
                                        //         setPhone={setPhone}
                                        //     />
                                        // )
                                    }[paymentMethod]
                                }

                                <Button onClick={formSubmitHandler} className="btn-supplementary-lg btn-hpf-deposit" disabled={isProcessing}>
                                    {
                                        isProcessing ? (
                                            <>
                                                <div>
                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                    &nbsp;&nbsp;<span>Processing</span>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div>
                                                    <span>Withdraw</span>
                                                </div>
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </>
                                        )
                                    }
                                </Button>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="hpf-redirect-container mt-4 animate__animated animate__faster animate__fadeIn">
                        <div className="hpf-redirect-summary">
                            <FontAwesomeIcon icon={faCheckCircle} className="hpf-check-icon mb-2" />
                            <h2 className="title-xs white-100">Success!</h2>
                            <p className="mb-0">Your withdrawal request is being processed and should be completed within 1 to 5 business days, depending on the withdrawal method. As soon as your withdrawal is processed, that amount will be deducted from your account balance</p>
                        </div>

                        {/* <a href={isRequestComplete} target="_blank" className="btn-supplementary-lg btn-hpf-redirect">
                            <div>
                                <span>Complete your payment</span>
                            </div>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </a> */}
                    </div>
                )
            }
        </>
    )
}

function CardFields({ firstName, setFirstName, lastName, setLastName, expireYear, setExpireYear, expireMonth, setExpireMonth, cardNumber, setCardNumber }) {
    return (
        <>
            <Row className="mb-2">
                <Col className="pe-1">
                    <FloatingLabel label="First Name">
                        <Form.Control
                            type="text"
                            value={firstName}
                            onChange={(e) => { setFirstName(e.target.value); }} />
                    </FloatingLabel>
                </Col>
                <Col className="ps-1">
                    <FloatingLabel label="Last Name">
                        <Form.Control
                            type="text"
                            value={lastName}
                            onChange={(e) => { setLastName(e.target.value); }} />
                    </FloatingLabel>
                </Col>
            </Row>
            <FloatingLabel label="Card number" className="mb-2">
                <Form.Control
                    type="text"
                    value={cardNumber}
                    maxLength={16}
                    onChange={(e) => { setCardNumber(e.target.value); }} />
            </FloatingLabel>
            <Row className="mb-2">
                <Col className="pe-1">
                    <FloatingLabel label="Expiry month (MM)">
                        <Form.Control
                            type="text"
                            value={expireMonth}
                            maxLength={2}
                            onChange={(e) => { setExpireMonth(e.target.value); }} />
                    </FloatingLabel>
                </Col>
                <Col className="ps-1">
                    <FloatingLabel label="Expiry year (YY)">
                        <Form.Control
                            type="text"
                            value={expireYear}
                            maxLength={2}
                            onChange={(e) => { setExpireYear(e.target.value); }} />
                    </FloatingLabel>
                </Col>
            </Row>
        </>
    )
}

function InteracFields({ firstName, setFirstName, lastName, setLastName, email, setEmail, phone, setPhone }) {
    return (
        <>
            <Row className="mb-2">
                <Col className="pe-1">
                    <FloatingLabel label="First Name">
                        <Form.Control
                            type="text"
                            value={firstName}
                            onChange={(e) => { setFirstName(e.target.value); }} />
                    </FloatingLabel>
                </Col>
                <Col className="ps-1">
                    <FloatingLabel label="Last Name">
                        <Form.Control
                            type="text"
                            value={lastName}
                            onChange={(e) => { setLastName(e.target.value); }} />
                    </FloatingLabel>
                </Col>
            </Row>
            <FloatingLabel label="Email" className="mb-2">
                <Form.Control
                    type="text"
                    value={email}
                    onChange={(e) => { setEmail(e.target.value); }} />
            </FloatingLabel>
            <FloatingLabel label="Phone" className="mb-2">
                <Form.Control
                    type="text"
                    value={phone}
                    onChange={(e) => { setPhone(e.target.value); }} />
            </FloatingLabel>
        </>
    )
}

function CoinspaidFields({ email, setEmail }) {
    return (
        <>
            <FloatingLabel label="Email" className="mb-2">
                <Form.Control
                    type="text"
                    value={email}
                    onChange={(e) => { setEmail(e.target.value); }} />
            </FloatingLabel>
        </>
    )
}

function SkrillFields({ email, setEmail }) {
    return (
        <>
            <FloatingLabel label="Email" className="mb-2">
                <Form.Control
                    type="text"
                    value={email}
                    onChange={(e) => { setEmail(e.target.value); }} />
            </FloatingLabel>
        </>
    )
}

function PayWithClickFields({ email, setEmail }) {
    return (
        <>
            {/* <FloatingLabel label="Email" className="mb-2">
                <Form.Control
                    type="text"
                    value={email}
                    onChange={(e) => { setEmail(e.target.value); }} />
            </FloatingLabel> */}
        </>
    )
}

function MuchBetterFields({ phone, setPhone, country, setCountry }) {
    return (
        <>
            <FloatingLabel label="Country" className="mb-2">
                <Form.Control
                    type="text"
                    value={country}
                    onChange={(e) => { setCountry(e.target.value); }} />
            </FloatingLabel>
            <FloatingLabel label="Phone" className="mb-2">
                <Form.Control
                    type="text"
                    value={phone}
                    onChange={(e) => { setPhone(e.target.value); }} />
            </FloatingLabel>
        </>
    )
}

/**
 * Coinspaid:
 * gateway: "099960426e4bcef9def8e0f6f2f10832"
 * fields:
 * key: email
 * key: wallet_address ?
 */


/**
 * Much Better:
 * gateway: "f480b7a9643ec6512408c2728d866aeb"
 * fields:
 * 
 * key: phone ?
 * label: "Phone Number in international format" max 50
 * 
 * key: country
 * label: "Country", max 50
 */