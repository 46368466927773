import { configureStore } from '@reduxjs/toolkit';
import user from './reducers/user';
import globalContent from './reducers/global-content';
import promotions from './reducers/promotions';
import gameCategories from './reducers/game-categories';
import bonuses from './reducers/bonuses';

export default configureStore({
  reducer: {
    user,
    globalContent,
    gameCategories,
    promotions,
    bonuses
  }
});