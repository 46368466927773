import React from "react";
import Loading from "./Loading";

import './loadingScreen.scss';

export default function LoadingScreen() {
    return (
        <div className="loading-container">
            <Loading />
        </div>
    );
}