import React from "react";
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

import './moreGamesLink.scss';

export default function MoreGamesLink({ centered }) {
    return (
        <div className="position-relative">
            <Link to={`games`} className={`btn more-games ${centered ? 'more-games-centered' : ''}`}>
                <div className="more-games-icon">
                    <FontAwesomeIcon icon={faChevronRight} />
                </div>
                <p>View all</p>
            </Link>
        </div>
    )
}