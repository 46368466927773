import React, { useState, useEffect } from "react";
import { useIdleTimer } from 'react-idle-timer'
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { logOutUser } from '../reducers/user';
import { Modal, Button } from 'react-bootstrap';
import { dbLogout } from "../utils/db-functions";


export default function IdleTimer() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const locale = useSelector((state) => state.globalContent.data.locale);

    const minutes = 60;

    const [show, setShow] = useState(false);
    const handleOnIdle = (event) => { setShow(true); }
    const handleClose = (event) => { setShow(false); dispatch(logOutUser()); }
    const handleLogin = (event) => { setShow(false); dispatch(logOutUser()); navigate(`/${locale}?login`); }
    
    useIdleTimer({
        timeout: 1000 * 60 * minutes,
        onIdle: handleOnIdle,
        debounce: 500,
    })

    useEffect(() => {
        if (show) { dbLogout(); }
    }, [show])


    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Session timed out</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>You have been idle. Please, login again.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn-main" onClick={() => { handleLogin() }}>
                    Login
                </Button>
            </Modal.Footer>
        </Modal>
    )
}