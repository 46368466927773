
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export default function DepositSummary({ sign, amount, promoCode }) {
    return (
        <div className="deposit-summary">
            <div>
                <p className="mb-0">Amount: <span className="white-100">{sign}{amount}</span></p>
                {
                    promoCode ? (
                        <p className="mb-0 mt-1 deposit-summary-promocode">Bonus: <span className="white-100">{promoCode}</span></p>
                    ) : (
                        <p className="mb-0 mt-1 deposit-summary-promocode">Bonus: <span className="white-100">No bonus selected</span></p>
                    )
                }
            </div>
            <div>
                <FontAwesomeIcon icon={faCheck} className="hpf-check-icon" />
            </div>
        </div>
    )
}