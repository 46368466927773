import React, { useEffect, useState, useRef } from "react";
import { Container, InputGroup, FormControl, Placeholder } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from "react-helmet-async";
import uniqid from 'uniqid';

import NavLink from '../components/NavLink';
import GamesList from "../components/GamesList";
import Lobby from "../components/Lobby";
import SlotsSubcategories from "../components/SlotsSubcategories";
import ProviderSubcategories from "../components/ProviderSubcategories";
import AllGamesList from "../components/AllGamesList";
import AllGamesSubcategories from "../components/AllGamesSubcategories";
import SearchList from "../components/SearchList";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import { BackgroundGradientGames } from "../components/BackgroundGradient";

//import './games.scss'

export default function Games() {

    const { categoryURL } = useParams();
    const { subcategoryURL } = useParams();

    const location = useLocation();
    const locale = useSelector((state) => state.globalContent.data.locale);
    const country = useSelector((state) => state.globalContent.data.country);
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const gameCategories = useSelector((state) => state.gameCategories.list || []);
    const filterCategory = Object.keys(gameCategories)?.length > 0 ? (gameCategories[`filter-${country.ISOcode}`] || gameCategories["filter"]) : null;

    const [search, setSearch] = useState('');
    const searchChangeHandler = (e) => { setSearch(e.target.value) }

    const searchInput = useRef(null)
    const gamesFilter = useRef(null)


    const displayCurrentCategory = () => {
        const category = gameCategories[`${categoryURL}-${country.ISOcode}`] || gameCategories[categoryURL];

        if (!category) {
            return (<div className="games-categories-loading"><Loading /></div>)
        }

        if (categoryURL === 'game-providers') {
            return <ProviderSubcategories subcategoryURL={subcategoryURL} />
        }

        if (categoryURL === 'all-slots') {
            return <SlotsSubcategories subcategoryURL={subcategoryURL} />
        }

        if (categoryURL === 'all-games') {
            return <AllGamesSubcategories subcategoryURL={subcategoryURL} />
            // return <AllGamesList />
        }

        if (category.categories.length > 0) {
            return <Lobby categoryURL={categoryURL} />
        }

        return <GamesList categoryURL={categoryURL} />
    }

    function isInViewport(el) {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    useEffect(() => {
        // console.log(gameCategories)
        // console.log(filterCategory)
        const activeCategory = document.querySelector(`#${categoryURL}`);

        if (location.search.includes('search')) {
            searchInput.current.click();
            searchInput.current.focus();
        }

        if (activeCategory?.offsetLeft && !isInViewport(activeCategory)) {
            gamesFilter.current.scrollLeft = activeCategory.offsetLeft - 50;
        }
    });

    return (
        <>
            <Helmet>
                <title>Games | Cherry Spins Casino</title>
            </Helmet>
            {/* <BackgroundHome /> */}
            <BackgroundGradientGames />
            <section className="position-relative">
                {/* <div className="games-banner-image"></div> */}
                <Container fluid className="games-search-wrapper">
                    <InputGroup className="games-search-field" >

                        <FormControl
                            id="gameSearchInput"
                            type="search"
                            placeholder="Enter game or provider"
                            value={search}
                            onChange={searchChangeHandler}
                            ref={searchInput}
                            enterKeyHint="Search"
                        />
                        {
                            search.length === 0 && (
                                <FontAwesomeIcon icon={faSearch} className="games-search-field-icon" />
                            )
                        }

                    </InputGroup>
                </Container>

                <Container fluid className={`games-filter-container-fixed ${userLoggedIn ? `games-logged-in-top` : ''}`} >
                    <div className="games-filter"ref={gamesFilter}>
                        {
                            !filterCategory ?
                                Array.apply(null, Array(7)).map((item, index) => (
                                    <Placeholder as="div" animation="glow" key={uniqid()}>
                                        <Placeholder className="games-category-placeholder" />
                                    </Placeholder>
                                )) :
                                filterCategory.categories.map(category =>
                                    <NavLink
                                        to={`/${locale}/games/${category.url}`}
                                        id={category.url}
                                        key={uniqid()}
                                        className="games-category"
                                        activeClassName="games-category-active"
                                        onClick={() => { setSearch(''); }}
                                    >
                                        {category.title}
                                    </NavLink>
                                )
                        }
                    </div>
                </Container>

                <Container fluid className="games-categories-container">
                    {
                        search.length > 0 ? (
                            <SearchList search={search} />
                        ) :
                        displayCurrentCategory()
                    }
                </Container>
            </section>
            <Footer />
        </>
    );
}