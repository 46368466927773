const param = 'tsource';

const hasTrafficSource = (url) => {
    return url.includes(param + "=")
}

/////////////
/// SET TRAFFIC SOURCE
/////////////
export const setTrafficSource = (url) => {
    if (!hasTrafficSource(url) || getTrafficSource()) { return; }

    const days = 3650;
    const tsource = url.split(param + "=")[1].split('&')[0];
    let cookie = param + "=" + encodeURIComponent(tsource);

    cookie += "; max-age=" + (days * 24 * 60 * 60);
    document.cookie = cookie + ";path=/";
}

/////////////
/// GET TRAFFIC SOURCE
/////////////
export const getTrafficSource = () => {
    let cookieArr = document.cookie.split(";");

    for (let i = 0; i < cookieArr.length; i++) {
        let cookiePair = cookieArr[i].split("=");
        
        if (param == cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1].trim());
        }
    }

    return '';
}

/////////////
/// GET FB COOKIE
/////////////
export const getFacebookCookie = () => {
    let cookieArr = document.cookie.split(";");

    for (let i = 0; i < cookieArr.length; i++) {
        let cookiePair = cookieArr[i].split("=");
        
        if ('fb_partners_user_id' == cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1].trim());
        }
    }

    return '';
}