import Gleap from "gleap";
import { dbUserFromToken } from './db-functions';

export async function itinializeGleap() { // user
    /*****************************/
    /** Check latest user information */
    let user;

    try {
        const result = await dbUserFromToken();
        user = result?.data?.user;
    } catch (error) {
        console.log(`Error, itinializeGleap, dbUserFromToken, ${error}`);
    }
    
    /*****************************/
    /** Initialize Gleap */
    if (!Gleap?.getInstance()?.initialized) {
        Gleap.initialize(process.env.REACT_APP_GLEAP_KEY);
    }

    Gleap.identify(user?.id || '0', {
        name: user?.firstName || '',
        email: user?.email || '',
        phone:  user?.phone || '',
        value: parseFloat(user?.depositsMade) || 0,
        companyId: user?.withdrawableBalance || 0,
        plan: user?.tags || '',
        // Passing the createdAt property allows you to set the correct createdAt date. (optional)
        // createdAt: new Date(),
        customData: {},
    });
}

export async function openGleap() { //user
    /*****************************/
    /** Check latest user information */
    let user;

    try {
        const result = await dbUserFromToken();
        user = result?.data?.user;
        console.log(user)
    } catch (error) {
        console.log(`Error, itinializeGleap, dbUserFromToken, ${error}`);
    }
    
    /*****************************/
    /** Initialize Gleap */
    if (!Gleap?.getInstance()?.initialized) {
        Gleap.initialize(process.env.REACT_APP_GLEAP_KEY);
    }

    Gleap.identify(user?.id || '0', {
        name: user?.firstName || '',
        email: user?.email || '',
        phone:  user?.phone || '',
        value: parseFloat(user?.depositsMade) || 0,
        companyId: user?.withdrawableBalance || 0,
        plan: user?.tags || '',
        // Passing the createdAt property allows you to set the correct createdAt date. (optional)
        // createdAt: new Date(),
        customData: {},
    });

    Gleap.open();
}