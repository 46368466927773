import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { updateUser } from '../reducers/user';
import { Modal, Button, FloatingLabel, Form, Alert, Spinner } from 'react-bootstrap';
import { dbLogin } from "../utils/db-functions";
import { Helmet } from "react-helmet-async";
import uniqid from 'uniqid';

//import './login.scss';
import logo from '../data/images/logo-color.png'

export default function Login() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);

    /** Close modal */
    const handleClose = () => { navigate(location.pathname); };

    /** Dismiss errors */
    const dismissErrors = () => { setErrors([]); }

    // State
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState([]);
    const [btnLoader, setBtnLoader] = useState(false);

    useEffect(() => {

        if (userLoggedIn) {
            /** Refreshing the window and navigating */

            let url = window.location.origin;
            // console.log(url)

            if (location.state?.forwardLocation?.pathname) url += location.state.forwardLocation.pathname;
            // console.log(url)

            if (location.state?.forwardLocation?.search) url += location.state.forwardLocation.search;
            // console.log(url)

            if (location.state?.forwardLocation?.hash) url += location.state.forwardLocation.hash;
            // console.log(url)

            window.location.replace(url);
        }

    }, [userLoggedIn, email, password, errors, btnLoader])

    const login = () => {

        setBtnLoader(true);

        dbLogin(email, password)
            .then(res => {
                if (res?.data?.user && res?.data?.user?.isActive) {
                    dispatch(updateUser({
                        isLoggedIn: true,
                        data: res.data.user
                    }));
                }
                else {
                    setErrors([...res.errors]);
                    setBtnLoader(false);
                }
            })
            .catch(err => {
                setErrors([...err.response.data.errors]);
                setBtnLoader(false);
            });
    };

    const handleLogin = (event) => {
        event.preventDefault();
        login();
    }

    return (
        <>
            <Helmet>
                <title>Login | Cherry Spins Casino</title>
            </Helmet>
            <Modal show={true} onHide={handleClose} className="login-container">

                <Modal.Header style={{ flexDirection: 'column', border: 'none', paddingTop: '1.5rem', paddingBottom: '0.5rem' }}>
                    <img src={logo} width="150px" alt="Cherry Spins" />
                </Modal.Header>

                <Modal.Body>
                    {
                        errors.map((error) => {
                            return (
                                <Alert key={uniqid()} variant="danger" onClose={() => dismissErrors()} dismissible >
                                    <span className='mb-0'>{error.message}</span>
                                    {
                                        !error.data ? null :
                                            error.data.map((item) => <span key={uniqid()} className='mb-0 mt-3'>{item.message}</span>)
                                    }
                                </Alert>
                            )
                        })
                    }

                    <form onSubmit={handleLogin}>
                        <FloatingLabel controlId="floatingInput" label="Email address" className="mb-2">
                            <Form.Control type="email" placeholder="name@example.com" value={email} onChange={e => setEmail(e.target.value)} enterKeyHint="Go" />
                        </FloatingLabel>

                        <FloatingLabel controlId="floatingPassword" label="Password" className="mb-3">
                            <Form.Control type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} enterKeyHint="Go" />
                        </FloatingLabel>

                        <input type="submit" data-role="none" style={{ visibility: 'hidden', position: 'absolute' }} />
                    </form>

                    <p className="mt-0">
                        <Link to={`${location.pathname}?forgot-password`}>
                            Forgot password?
                        </Link>
                    </p>

                    <p className="mb-0">
                        Don't have an account yet?&nbsp;
                        <Link to={`${location.pathname}?register`} state={{ forwardLocation: location.state?.forwardLocation }}>
                            Create account
                        </Link>
                    </p>



                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-secondary ps-4 pe-4' onClick={handleClose}>
                        Close
                    </Button>
                    <Button className='btn-main ps-4 pe-4' onClick={login} disabled={btnLoader}>
                        {
                            !btnLoader ? 'Log in' : (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />&nbsp;&nbsp;Log in
                                </>

                            )
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}