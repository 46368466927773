
import React, { useEffect } from "react";
import { useSelector } from 'react-redux';
import uniqid from 'uniqid';

import GamesRow from './GamesRow';

export default function Lobby({ categoryURL }) {
    const country = useSelector((state) => state.globalContent.data.country)
    const gameCategories = useSelector((state) => state.gameCategories.list);
    const category = gameCategories ? (gameCategories[`${categoryURL}-${country.ISOcode}`] || gameCategories[categoryURL]) : null;

    useEffect(() => { });

    return (
        <section className="lobby-section">
            <div className="lobby-container">
                {!category ? (
                    <>
                        <GamesRow categoryURL={null} />
                        <GamesRow categoryURL={null} />
                    </>
                ) : (
                    category.categories.map((subcategory, i) =>
                        <GamesRow categoryURL={subcategory.url} key={`${i}${subcategory.id}`} />
                    )
                )}
            </div>
        </section>
    );
}