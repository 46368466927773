import { createSlice } from "@reduxjs/toolkit";
import { dbGetPromotions } from '../utils/db-functions'

export const promotions = createSlice({
    name: "promotions",
    initialState: {
        list: null,
    },
    reducers: {
        updatePromotions: (state, action) => {

            Object.assign(state, {
                ...state, ...{
                    list: action.payload.list.sort((curr, next) => curr.rank - next.rank ),
                }
            });

            // action.payload.list.sort((promo, next) => promo.rank - next.rank);

            // /** This is a list of active promotions for the current country */
            // const promotionsList = [];

            // /** Current user */
            // const user = action.payload.user;

            // action.payload.list.forEach(promotion => {

            //     /** Checks for promotions for logged in users */
            //     if (promotion && Object.keys(user).length > 0) {

            //         /** If promotion deposit flow position is different */
            //         if (promotion.depositFlowPosition !== user.depositsMade + 1 && promotion.depositFlowPosition !== 0) { return; }

            //         /** Do not show logged out promotions */
            //         if (promotion.isLoggedOutPromotion) { return; }

            //         if (promotion.bonus) {

            //             /** Getting the currency option */
            //             const option = promotion.bonus.currencyList.filter(item => {
            //                 return item.currency.id === user.currency.id;
            //             })[0];

            //             /** Check if bonus supports user currency */
            //             if (!option) { return; }

            //             /** If user already claimed the bonus */
            //             if (user.bonusesClaimed?.includes(promotion.bonus.promoCode)) { return; }

            //             /** If bonus deposit flow  position is different */
            //             if (promotion.bonus.depositFlowPosition !== user.depositsMade + 1 && promotion.bonus.depositFlowPosition !== 0) { return; }

            //         }
            //     }

            //     /** Checks for promotions for logged out users */
            //     if (promotion && Object.keys(user).length === 0) {

            //         if (!promotion.isLoggedOutPromotion) { return; }

            //     }

            //     //promotionsList[promotion.promotionId] = promotion;

            //     promotionsList.push(promotion);

            // });

            // Object.assign(state, {
            //     ...state, ...{
            //         list: promotionsList.sort((curr, next) => curr.rank - next.rank ),
            //     }
            // });

        }
    }
});

export const GetPromotionsAsync = (country, language, user) => (dispatch) => {
    dbGetPromotions(country, language)
        .then(res => {
            if (res.data.docs) {

                dispatch(updatePromotions({
                    list: res.data.docs,
                    user: user,
                }));
            }
        })
        .catch(error => { })
}

// Action creators are generated for each case reducer function
export const {
    updatePromotions
} = promotions.actions;

export default promotions.reducer;