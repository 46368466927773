import React, { useEffect } from "react";
import { Player } from '@lottiefiles/react-lottie-player';
import logoColor from '../data/logo/logo-color-cherryspins.json';

//import './loading.scss';

export default function Loading({ color }) {
    // const loadingClassName = () => {
    //     switch (color) {
    //         case 'supplementary':
    //             return 'loading loading-supplementary';
    //         case 'white':
    //             return 'loading loading-white';
    //         default:
    //             return 'loading';
    //     }
    // }

    useEffect(() => { })

    if(color === 'white') {
        return (
            <Player
                autoplay
                loop
                src={logoColor}
                style={{ height: '80px', width: '200px' }}
            />
        )
    }

    return (
        // <div className={loadingClassName()}>
        //     <div></div>
        //     <div></div>
        //     <div></div>
        //     <div></div>
        // </div>
        // <lottie-player src="https://cherryspins.b-cdn.net/logo/logo-color-cherryspins.json"  background="transparent"  speed="1"  style="width: 300px; height: 300px;"  loop controls autoplay></lottie-player>
        <Player
            autoplay
            loop
            src={logoColor}
            style={{ height: '80px', width: '200px' }}
        />
    );
}