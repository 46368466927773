import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

export default function WithdrawMethod({ choosePaymentMethod }) {
    const user = useSelector((state) => state.user.data)

    useEffect(() => {
        setTimeout(() => { window.scrollTo(0, 0); }, 0);
    }, [])

    return (
        <>
            {/* <p>Choose your withdrawal method</p> */}
            <br />
            {
                {
                    'CAD': <PaymentMethodsCAD {...{ choosePaymentMethod }} />,
                    'USD': <PaymentMethodsUSD {...{ choosePaymentMethod }} />,
                    'EUR': <PaymentMethodsEUR {...{ choosePaymentMethod }} />,
                    'NZD': <PaymentMethodsNZD {...{ choosePaymentMethod }} />,
                }[user?.currency?.code]
            }
        </>
    )
}

function PaymentMethodsCAD({ choosePaymentMethod }) {
    return (
        <>
            <Button onClick={() => { choosePaymentMethod('interac_etransfer_cad') }}
                className="btn-secondary btn-choose-payment interac">
                <div>
                    {/* https://cherryspins.b-cdn.net/images/interac-color-logo-4.png */}
                    <img src="https://cherryspins.b-cdn.net/images/interac-logo-logo.png" alt="Interac" /> 
                    <span>Interac® e-Transfer</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            <Button onClick={() => { choosePaymentMethod('payment_card_eur') }}
                className="btn-secondary btn-choose-payment visa">
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/visa-logo-3.png" alt="Visa" />
                    <span>Visa / Mastercard</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>
            
            <p style={{textAlign: "left",fontSize: "12px"}} className="white-38">®Trade-mark of Interac Corp. Used Under License.</p>
        </>
    )
}

function PaymentMethodsUSD({ choosePaymentMethod }) {
    return (
        <>
            <Button onClick={() => { choosePaymentMethod('payment_card_eur') }}
                className="btn-secondary btn-choose-payment visa">
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/visa-logo-3.png" alt="Visa" />
                    <span>Visa/Mastercard</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>
        </>
    )
}

function PaymentMethodsEUR({ choosePaymentMethod }) {
    return (
        <>
            <Button onClick={() => { choosePaymentMethod('payment_card_eur') }}
                className="btn-secondary btn-choose-payment visa">
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/visa-logo-3.png" alt="Visa" />
                    <span>Visa/Mastercard</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>
        </>
    )
}

function PaymentMethodsNZD({ choosePaymentMethod }) {
    return (
        <>
            <Button onClick={() => { choosePaymentMethod('payment_card_eur') }}
                className="btn-secondary btn-choose-payment visa">
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/visa-logo-3.png" alt="Visa" />
                    <span>Visa/Mastercard</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>
        </>
    )
}