
import React from "react";
import { Placeholder } from 'react-bootstrap';

export default function CategoryTitle({title, ...props}) {
    return (
        title ? (
            <h2 className={`title-xs white-100 mb-3 ${props.className}`}>{title}</h2>
        ) : (
            <Placeholder as="h3" animation="glow" className={`mb-3 ${props.className}`}>
                <Placeholder xs={12} style={{ width: "170px", borderRadius: "10px" }} />
            </Placeholder>
        )
    )
}