import React, { useEffect, useState } from "react";
import { dbIPLookup } from "../utils/db-functions";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { overrideDepositMinAmountGlobalContent } from '../reducers/global-content';
import { overrideDepositMinAmountBonus } from "../reducers/bonuses";
import 'dotenv/config';

export default function IPBlock() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const locale = useSelector((state) => state.globalContent.data.locale);
    const bonuses = useSelector((state) => state.bonuses.list);
    const global = useSelector((state) => state.globalContent.data);
    const user = useSelector((state) => state.user.data);
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);

    const [override, setOverride] = useState(false);
    const [country, setCountry] = useState(null);
    const [isVPN, setIsVPN] = useState(true);

    useEffect(() => {
        const countries = [
            'Cyprus',
            'United States',
            'Curaçao',
            'Curacao',
            'Croatia'
        ]

        const regions = [
            // 'Ontario',
        ]

        //console.log(process.env.REACT_APP_DEPOSIT_OVERRIDE)
        // console.log(country)
        // console.log(isVPN)

        /** IP minimum bonus override Canada */
        if (Object.keys(bonuses)?.length > 0 && !override && user?.depositsMade < 1 && user?.depositMinAmount > 0 && country === 'Canada' && !isVPN && process.env.REACT_APP_DEPOSIT_OVERRIDE === 'TRUE') {
            dispatch(overrideDepositMinAmountGlobalContent({ global, depositMinAmount: parseFloat(user?.depositMinAmount) }));
            dispatch(overrideDepositMinAmountBonus({ bonuses, depositMinAmount: parseFloat(user?.depositMinAmount) }))
            setOverride(true);
        }

        /** IP minimum bonus override Germany */
        if (Object.keys(bonuses)?.length > 0 && !override && user?.depositsMade < 1 && user?.depositMinAmount > 0 && country === 'Germany' && !isVPN && process.env.REACT_APP_DEPOSIT_OVERRIDE === 'TRUE') {
            dispatch(overrideDepositMinAmountGlobalContent({ global, depositMinAmount: parseFloat(user?.depositMinAmount) }));
            dispatch(overrideDepositMinAmountBonus({ bonuses, depositMinAmount: parseFloat(user?.depositMinAmount) }))
            setOverride(true);
        }

        if (Object.keys(bonuses)?.length === 0 && !country) {
            dbIPLookup().then(res => {
                // if (res?.data?.status === 'success') {
                //     // if (countries.includes(res?.data?.country) || regions.includes(res?.data?.regionName)) {
                //     //     navigate(`${locale}/region-blocked`);
                //     // }

                //     setCountry(res?.data?.country);
                // }

                //console.log(res.data);

                if (countries.includes(res?.data?.country) || regions.includes(res?.data?.region)) {
                    navigate(`${locale}/region-blocked`);
                }

                if (res?.data?.security?.is_vpn === false) {
                    setIsVPN(res?.data?.security?.is_vpn);
                }

                setCountry(res?.data?.country);
            })
        }
    }, [bonuses, country]);

    return null;
}

// import React, { useEffect, useState } from "react";
// import { dbIPLookup } from "../utils/db-functions";
// import { useSelector, useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { incomeAccessGetBtag } from "../utils/income-access";
// import { overrideDepositMinAmountGlobalContent } from '../reducers/global-content';
// import { overrideDepositMinAmountBonus } from "../reducers/bonuses";

// export default function IPBlock() {
//     const dispatch = useDispatch();
//     const navigate = useNavigate();

//     const locale = useSelector((state) => state.globalContent.data.locale);
//     const bonuses = useSelector((state) => state.bonuses.list);
//     const global = useSelector((state) => state.globalContent.data);
//     const user = useSelector((state) => state.user.data);
//     const userLoggedIn = useSelector((state) => state.user.isLoggedIn);

//     const [override, setOverride] = useState(false);
//     const [country, setCountry] = useState(null);

//     useEffect(() => {
//         const countries = [
//             'Cyprus',
//             'United States',
//             'Curaçao',
//             'Curacao'
//         ]

//         const regions = [
//             // 'Ontario',
//         ]

        // /** IP minimum bonus override */
        // if (Object.keys(bonuses)?.length > 0 && !override && user?.depositsMade < 1 && user?.depositMinAmount > 0) { //&& country === 'Canada'
        //     console.log('IP test workded')
        //     dispatch(overrideDepositMinAmountGlobalContent({ global, depositMinAmount: parseFloat(user?.depositMinAmount) }));
        //     dispatch(overrideDepositMinAmountBonus({ bonuses, depositMinAmount: parseFloat(user?.depositMinAmount) }))
        //     setOverride(true);
        // }

//         if (Object.keys(bonuses)?.length === 0 && !country) {
//             console.log('dbIPLookup');
//             /** Getting country via IP */
//             dbIPLookup().then(res => {
//                 if (res?.data?.status === 'success') {

//                     /** Region blocked */
//                     if (countries.includes(res?.data?.country) || regions.includes(res?.data?.regionName)) {
//                         navigate(`${locale}/region-blocked`);
//                     }

//                     /** Set country */
//                     setCountry(res?.data?.country)
//                 }
//             })
//         }

//     }, [bonuses, country]);

//     return null;
// }