import { createSlice } from "@reduxjs/toolkit";
import { dbGetGlobalContent } from '../utils/db-functions';

export const globalContent = createSlice({
    name: "globalContent",
    initialState: {
        isLoading: true,
        data: {
            country: {}, // stuff...
            language: '', // en
            locale: '', // ca-en
            limits: [],
        }
    },
    reducers: {
        updateGlobalContent: (state, action) => {
            /**********************/
            /** Updating the store */
            Object.assign(state, { ...state, ...action.payload });
        },
        overrideDepositMinAmountGlobalContent: (state, action) => {

            let newLimits = [];

            /***********************/
            /** Updating deposit min amount */
            if (action?.payload?.global?.limits?.length > 0) {
                for (let limit of action.payload.global.limits) {
                    let temp = Object.assign({}, limit);
                    temp.depositMinAmount = action?.payload?.depositMinAmount;
                    newLimits.push(temp);
                }
            }

            let newData = Object.assign({}, { ...action.payload.global, ...{ limits: newLimits } });

            // console.log('newData')
            // console.log(newData)

            Object.assign(state, { ...state, ...{ data: newData } });
        }
    }
});

export const getGlobalContentAsync = (country, language) => async (dispatch) => {
    dbGetGlobalContent(language)
        .then(res => {

            dispatch(
                updateGlobalContent({
                    isLoading: false,
                    data: {
                        ...res.data, ...{
                            country: country,
                            language: language,
                            locale: `${country.ISOcode}-${language}`,
                        }
                    },
                })
            );

        })
        .catch(err => { })
};

// Action creators are generated for each case reducer function
export const {
    updateGlobalContent,
    overrideDepositMinAmountGlobalContent
} = globalContent.actions;

export default globalContent.reducer;

/**
ISOcode :  "ca"
additionalCurrencies :  []
additionalLanguages :  []
createdAt :  "2022-06-13T13:34:33.349Z"
defaultCurrency :  {id: '62a73d45972896b1584f9ba3', code: 'EUR', sign: '€', subunits: 100, createdAt: '2022-06-13T13:36:05.204Z', …}
defaultLanguage :  "en"
id :  "62a73ce960f73ee255492537"
isDefaultCountry :  true
name : "Canada"
paymentMethods :  "test,test,test"
updatedAt :  "2022-07-29T17:50:59.070Z"
 */