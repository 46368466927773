import React, { useEffect, useState, useRef } from "react";
import { Button, Alert, Accordion, Form, FloatingLabel, Modal } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { dbPraxisWithdraw } from '../utils/db-functions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { Helmet } from "react-helmet-async";

import Loading from "../components/Loading";
import WithdrawMethod from "../components/WithdrawMethods";
import WithdrawRedirect from "../components/WithdrawRedirect";
import paymentLogos from "../data/images/payment-logos.svg";

export default function WithdrawNew() {
    const user = useSelector((state) => state.user.data)
    const globalLimits = useSelector((state) => state.globalContent.data.limits);
    const limits = globalLimits.filter(item => {
        return item.currency === user?.currency?.id;
    })[0] || null;

    const step1 = useRef(null);
    const step2 = useRef(null);

    const [amount, setAmount] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [step, setStep] = useState(1);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const roundNumber = (num) => {
        return Math.round((num + Number.EPSILON) * 100) / 100;
    }

    const choosePaymentMethod = (method) => {
        if (user.withdrawableBalance < limits.withdrawalMinAmount ||
            amount < limits.withdrawalMinAmount ||
            amount > user.withdrawableBalance ||
            amount > limits.withdrawalMaxAmount) {
            return false;
        }

        setPaymentMethod(method)
        transitionToStep(2)
    }

    const transitionToStep = (step) => {
        if (step === 1) {
            step2.current.classList.add("animate__fadeOutRight");
            setTimeout(() => {
                setStep(step);
                step1.current.classList.add("animate__fadeIn");
            }, 300);
        }

        if (step === 2) {
            step1.current.classList.add("animate__fadeOutLeft");
            setTimeout(() => {
                setStep(step);
                step2.current.classList.add("animate__fadeIn");
            }, 300);
        }
    }

    useEffect(() => { })

    return (
        <>
            <Helmet>
                <title>Withdraw | Cherry Spins Casino</title>
            </Helmet>

            <section className="withdraw-container">
                <div>
                    {
                        step === 1 && (
                            <div className="withdraw-section text-center animate__animated animate__faster mb-4" ref={step1}>
                                <h2 className="h4 h4-bold mb-1">Withdrawable balance</h2>
                                <h3 className="h3 mb-3">
                                    {user.currency.sign}
                                    {Number.parseFloat(user.withdrawableBalance)}
                                </h3>

                                <Form.Group controlId="amount" className="mb-4">
                                    <FloatingLabel label={`Amount (${user.currency.sign})`} >
                                        <Form.Control
                                            type="number"
                                            className="amount-input"
                                            aria-label="Withdrawal amount"
                                            value={amount}
                                            onChange={(e) => { setAmount(e.target.value) }}
                                            isValid={amount >= limits.withdrawalMinAmount && amount <= user.withdrawableBalance && amount <= limits.withdrawalMaxAmount}
                                            isInvalid={parseInt(amount) !== 0 && (amount < limits.withdrawalMinAmount || amount > user.withdrawableBalance || amount > limits.withdrawalMaxAmount)}
                                        />
                                        <Form.Control.Feedback type="invalid" tooltip>
                                            Balance is not sufficient or amount is not in the range of {user.currency.sign}{limits.withdrawalMinAmount} and {user.currency.sign}{limits.withdrawalMaxAmount}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>

                                {/* <div className="btn-withdraw-container">
                                    <Button
                                        className="btn-supplementary btn-withdraw"
                                        onClick={withdraw}
                                    >Withdraw</Button>
                                </div> */}

                                {
                                    user.withdrawableBalance === 0 && (
                                        <p className="mb-0 mt-3">There is no money to withdraw yet.</p>
                                    )
                                }
                                {
                                    (user.withdrawableBalance > 0 && user.withdrawableBalance < limits.withdrawalMinAmount) && (
                                        <p className="mb-0 mt-3">The minimum withdrawal amount is {user.currency.sign}{limits.withdrawalMinAmount}</p>
                                    )
                                }
                                {
                                    (user.withdrawableBalance > 0 && user.withdrawableBalance >= limits.withdrawalMinAmount) && (
                                        <p className="mb-0 mt-3">Choose amount and method to withdraw.</p>
                                    )
                                }

                                <WithdrawMethod {...{ choosePaymentMethod }} />
                            </div>
                        )
                    }

                    {
                        step === 2 && (
                            <div ref={step2} className="animate__animated animate__faster">
                                <div className="withdraw-section">
                                    <WithdrawRedirect {...{ amount, paymentMethod }} />
                                </div>
                                {/* <Button className="btn-secondary btn-withdraw-back" onClick={() => { transitionToStep(1) }}>
                                    <FontAwesomeIcon icon={faChevronLeft} /> &nbsp;
                                    Return to withdraw amount
                                </Button>
                                <section id="withdrawIframeSection" className="withdraw-section withdraw-iframe-section">
                                    <h2 className="h5 mb-3">Complete your withdrawal</h2>
                                    <div className="withdraw-iframe-wrapper">
                                        <PraxisFrameWithdraw amount={amount} />
                                    </div>
                                </section> */}
                            </div>
                        )
                    }
                </div>

                <div className="withdrawal-icons-container">
                    {/* <img className="withdrawal-icons" src="https://ik.imagekit.io/vbh4oaoonf1/images/footer-logos_uV8LUT2dq.svg" alt="" /> */}
                    <img className="withdrawal-icons" src={paymentLogos} alt="" />
                </div>


                <h2 className="h3 h4-bold text-center mb-3">FAQ</h2>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Why can’t I withdraw available funds from my account?</Accordion.Header>
                        <Accordion.Body>
                            In case you have balance on your account but the funds are still not available for withdrawal, there can be 2 reasons for this:
                            <ul>
                                <li>
                                    You don’t have sufficient funds on your account for the minimal withdrawal ($30 or the equivalent amount in your account currency).
                                </li>
                                <li>
                                    Due to security issues and procedures against money laundering, fraud or bond abuse, deposits made cannot be withdrawn without having been wagered at 100%. So for example if you deposited CAD 100, this means that you need to wager CAD 100 after which your winnings will become available for withdrawal.
                                </li>
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>What is the minimum withdrawable amount?</Accordion.Header>
                        <Accordion.Body>
                            Minimum withdrawable amount on Cherry Spins is $30 or the equivalent amount in your account currency.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Are there any fees for withdrawing funds?</Accordion.Header>
                        <Accordion.Body>
                            No. All withdrawals are 100% free of charge.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Is withdrawing funds from Cherry Spins casino safe and secure?</Accordion.Header>
                        <Accordion.Body>
                            All withdrawals on our website are 100% secure as every transaction is encrypted using the latest SSL. In addition, all the payment processing providers we are using are fully secure and licensed (Visa, Mastercard, Interac etc.)
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>How to contact you in case of any withdrawal related questions?</Accordion.Header>
                        <Accordion.Body>
                            For any withdrawal related questions and inquiries please write to our chat support and we’ll be happy to assist you.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </section>
            <RestrictedWithdrawal {...{ isModalVisible, setIsModalVisible }} />
        </>
    );
}

function PraxisFrameWithdraw({ amount }) {

    const user = useSelector((state) => state.user.data)
    const container = useRef(null)
    const [iframeLoaded, setIframeLoaded] = useState(false)

    const data = {
        amount: amount,
        currency: user.currency.code,
        country: user.country.ISOcode,
        return_url: window.location.origin
    };

    useEffect(() => {
        !iframeLoaded && setTimeout(() => { window.scrollTo(0, 0); }, 0);

        !iframeLoaded && dbPraxisWithdraw(data)
            .then(res => {
                // console.log(res.data)

                setTimeout(() => {
                    setIframeLoaded(true);
                }, 3000)

                const AUTOCLOSE = false;
                const MODE = 'iframe';
                const LOCALE = 'en-GB';

                let cashier = window.PraxisCashier({
                    auth_token: res?.data?.session?.auth_token,  // auth_token received in response to API call
                    container: container.current,//document.querySelector("[id='praxisContainerId']"),//, // block where the cashier iframe or cashier window controls will be added
                    autoclose: AUTOCLOSE, // whether to close the cashier window upon transaction attempt
                    mode: MODE, // supported values: 'iframe', 'tab', 'window'
                    locale: LOCALE // optional, locale for Praxis login button, browser locale is default
                });

                cashier.on('resize', function (data) {
                    if (MODE === 'iframe') {
                        let iframe = cashier.getCashierIframe();

                        if (iframe) {
                            iframe.style.height = data.height + 'px';
                            iframe.style.width = (document.querySelector("[id='withdrawIframeSection']").offsetWidth - 30) + 'px';
                        }
                    }
                });

                cashier.render();
            });
    }, [])

    return (
        <>
            {!iframeLoaded && (
                <>
                    <Loading color='supplementary' />
                    <p className="withdrawal-loading-dots mt-0 mb-0">Establishing a secure connection</p>
                </>
            )}
            <div ref={container} className={`withdraw-container ${!iframeLoaded ? 'withdraw-container-hidden' : ''}`}></div>
        </>
    );
}

function RestrictedWithdrawal({ isModalVisible, setIsModalVisible }) {
    const user = useSelector((state) => state.user.data)

    function onHideHandler() {
        setIsModalVisible(false);
    }

    return (
        <Modal show={isModalVisible} onHide={onHideHandler} size="md">
            <Modal.Header style={{ paddingBottom: "0px", paddingTop: "30px" }}>
                <h1 className="title-xs white-100 text-center w-100">Withdrawal will forfeit your bonus&nbsp;funds!</h1>
            </Modal.Header>
            <Modal.Body className="text-center">
                <p className="mb-0">Active bonus:</p>
                <p className="white-100" style={{ fontWeight: "500" }}>{user.bonus[0]?.promoCode}</p>
                <p className="white-100">We recomend to finish wagering your bonus before withdrawing not to lose your bonus funds and winnings.</p>
                <p>Since you have an active bonus, both your deposit and bonus funds need to be wagered as per bonus terms. If you choose to forfeit your bonus and withdraw, please reach out to our support team via chat or e-mail.</p>
            </Modal.Body>
        </Modal >
    )
}