import React, { useEffect, useState } from "react";
import { dbAddressLookup, dbAddressComplete } from "../utils/db-functions";
import { Button, Form } from 'react-bootstrap';

// import './addressLookupRegForm.scss';

export default function AddressLookupRegForm({ getAddressFromLookup, ...props }) {

    const [options, setOptions] = useState([])
    const [value, setValue] = useState('');

    const addressLookup = (params) => {
        dbAddressLookup(params)
            .then((res) => {
                const data = res?.data?.data;
                if (!data) return;

                let addresses = []
                data.forEach(item => { addresses.push(item.preview); });
                addresses.length > 0 && setOptions(addresses);
            })
    }

    const addressComplete = (preview) => {
        const data = {
            partialStreet: preview.address,
            cityFilter: preview.city,
            stateFilter: preview.prov
        }

        dbAddressComplete(data)
            .then(res => {
                console.log(res?.data?.data?.address); //address, city, pc, prov

                if (!res?.data?.data?.address) return;
                const result = res?.data?.data?.address;

                getAddressFromLookup({
                    lStreet: result?.address || '',
                    lCity: result?.city || '',
                    lState: result?.prov || '',
                    lZip: result?.pc || ''
                })
            })
    }

    useEffect(() => { }, [value, options]);

    return (
        <>
            <Form.Group controlId="address" id="address" name="address">
                <Form.Control
                    type="text"
                    className="registration-field"
                    placeholder="Example: 777 Lucky ST"
                    value={value}
                    onChange={(e) => {
                        const val = e.target.value;
                        setValue(val);
                        val && addressLookup(val
                            .toLowerCase()
                            .replace(/street/g, 'ST')
                            .replace(/road/g, 'RD')
                            .replace(/,/g, '')
                            .replace(/ /g, '+')
                        )
                    }} />
            </Form.Group>

            {props.children}

            <div className="mt-2 mb-2 address-lookup-list-container">
                {
                    options?.map(preview => (
                        <Button
                            className="btn-secondary mb-1"
                            onClick={() => { addressComplete(preview) }}
                        >{`${preview?.address}, ${preview?.city}, ${preview?.prov}`}
                        </Button>
                    ))
                }
            </div>
        </>
    );
}