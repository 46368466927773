
import React, { useEffect, useState } from "react";
import { Container } from 'react-bootstrap';
import { dbSearchGames } from "../utils/db-functions";
import uniqid from 'uniqid';

import GameTile from "./GameTile";
import LoadMore from "./LoadMore";

export default function SearchList({ search }) {
    const [games, setGames] = useState([]);
    const [currentSearch, setCurrentSearch] = useState('');
    const [pages, setPages] = useState({
        page: 1,
        total: null
    })

    const fetchPage = (searchWord, pageNumber) => {
        const currentGames = pageNumber === 1 ? [] : games;

        dbSearchGames(searchWord, pageNumber)
            .then(res => {
                if (!res.data) { return; }
                setGames([...currentGames, ...res.data.docs]);
                setCurrentSearch(searchWord);
                setPages({page: pageNumber, total: res.data.totalPages});
            })
    }

    useEffect(() => {
        if (search !== currentSearch) { fetchPage(search, 1); }
    }, [pages.page, search])

    return (
        <Container id="search-container" fluid="lg" className={`game-category-container`}>
            <h3 className="h3 mb-3">Search: {search}</h3>

            <div className={`game-list-wrapper`}>
                { games.map((game, i) => <GameTile game={game} key={`search-list-${game.id}-${i}`} />) }
            </div>
            
            <LoadMore 
                isVisible={ pages.total !== null && pages.page < pages.total }
                onClick={() => { fetchPage(currentSearch, pages.page + 1) }}
            />
        </Container>
    );
}