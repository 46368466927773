import React, { useEffect, useRef } from "react";
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Navigate } from "react-router-dom";
import uniqid from 'uniqid';

import NavLink from './NavLink';
import ProvidersList from './ProvidersList';
import ScrollButtonsSubcategories from "./ScrollButtonsSubcategories";

import './subcategories.scss';

export default function ProviderSubcategories({ subcategoryURL }) {

    const country = useSelector((state) => state.globalContent.data.country)
    const locale = useSelector((state) => state.globalContent.data.locale);
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const categories = useSelector((state) => state.gameCategories.list || []);
    const category = categories[`game-providers-${country.ISOcode}`] || categories["game-providers"];
    const container = useRef(null);

    useEffect(() => {});

    /** Default to first provider in the list */
    if (!subcategoryURL) {
        return !category.providers[0].provider ? null :
            <Navigate to={`/${locale}/games/game-providers/${encodeURI(category.providers[0].provider.name)}`} replace />
    }

    return (<>

        {/* Provider sub-navigation */}
        <Container fluid className={`theme-filter-container-fixed ${userLoggedIn ? 'logged-in-top' : ''}`} >
            <div className="theme-filter-wrapper">

                {/* Provider list */}
                <div className="theme-filter" ref={container}>
                    {
                        category?.providers.length <= 0 ? null :
                            category.providers.map(item => {
                                return <NavLink to={`/${locale}/games/${category.url}/${encodeURI(item.provider.name)}`}
                                    id={item?.provider?.name?.replace(/ /g, '')}
                                    className="theme-category"
                                    activeClassName="theme-category-active"
                                    key={uniqid()}
                                    end
                                >
                                    {item.provider.name}
                                </NavLink>
                            })
                    }
                </div>

                {/* Scroll */}
                <ScrollButtonsSubcategories container={container} subcategoryURL={subcategoryURL}/>
            </div>
        </Container>

        {/* Provider games */}
        <ProvidersList name={subcategoryURL} />
    </>
    );
}