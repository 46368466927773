import Axios from 'axios';
import 'dotenv/config';

const voluumPostbackURL = process.env.REACT_APP_VOLUUM_POSTBACK_URL;
const tokens = [
    'cid=',
    's1=',
    's2=',
]

/////////////
/// POSTBACK
/////////////
export const voluumSendPostback = (event, amount) => {
    const cid = window.sessionStorage.getItem('cid');
    if (!cid) { return; }

    return Axios({
        method: 'get',
        url: `${voluumPostbackURL}?cid=${cid}&et=${event}${amount ? ('&currency=CAD&payout=' + amount) : ''}`
    }).then(res => console.log(res))
}

/////////////
/// URL EVALUATION
/////////////
export const voluumCheckURL = (url) => {
    const checkTokens = (token) => url.includes(token);
    return tokens.some(checkTokens)
}

/////////////
/// GET CLICK ID
/////////////
export const voluumGetClickID = (url) => {
    let clickID = null;

    for (let i = 0; i < tokens.length; i++) {
        if (url.includes(tokens[i])) {
            clickID = url.split(tokens[i])[1].split('&')[0];
        }
    }

    return clickID;
}