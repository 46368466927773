
import React, { useEffect, useRef } from "react";
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Navigate } from "react-router-dom";
import uniqid from 'uniqid';

import TagsList from './TagsList';
import NavLink from './NavLink';
import ScrollButtonsSubcategories from "./ScrollButtonsSubcategories";

import './subcategories.scss';

export default function AllGamesSubcategories({ subcategoryURL }) {
    const country = useSelector((state) => state.globalContent.data.country)
    const gameCategories = useSelector((state) => state.gameCategories.list);
    const category = gameCategories ? (gameCategories[`all-games-${country.ISOcode}`] || gameCategories[`all-games`]) : null;
    const locale = useSelector((state) => state.globalContent.data.locale);
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const container = useRef(null)

    useEffect(() => {});

    if (!subcategoryURL) {
        return !category.tags[0] ? null :
            <Navigate to={`/${locale}/games/all-games/${encodeURI(category.tags[0].tag)}`} replace />
    }

    return (
        <>
            <Container fluid className={`theme-filter-container-fixed ${userLoggedIn ? 'logged-in-top' : ''}`} >
                <div className="theme-filter-wrapper">

                    {/* Tags */}
                    <div className="theme-filter" ref={container}>
                        {
                            !category ? null :
                                category.tags.map((item, i) => {
                                    return <NavLink id={item.tag.replace(/ /g, '')} key={uniqid()}
                                        className="theme-category"
                                        end
                                        activeClassName="theme-category-active"
                                        to={`/${locale}/games/${category.url}/${encodeURI(item.tag)}`}>
                                        {item.tag}
                                    </NavLink>
                                })
                        }
                    </div>

                    {/* Scroll */}
                    <ScrollButtonsSubcategories container={container} subcategoryURL={subcategoryURL} />
                </div>
            </Container>
            
            {/* <TagsList category={category} tag={decodeURI(subcategoryURL)} /> */}
            <TagsList tag={decodeURI(subcategoryURL)} />
        </>
    );
}