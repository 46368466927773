import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Modal, Button, FloatingLabel, Form } from 'react-bootstrap';
import { dbForgotPassword } from "../utils/db-functions";

export default function ForgotPassword({ backURL, forwardURL }) {
    const navigate = useNavigate();
    const location = useLocation();
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);

    // State
    const [email, setEmail] = useState('');
    const [sent, setSent] = useState(false);

    const handleClose = () => {
        navigate(location.pathname);
    };

    useEffect(() => { })

    const forgotPassword = () => {
        dbForgotPassword(email)
            .then((res) => {
                console.log(res)
                if (res.data.message === 'Success') {
                    setSent(true);
                }
            })
            .catch(err => { });
    };

    if (userLoggedIn) {
        return <Navigate to={location.pathname} replace />
    }

    return (
        <Modal show={true} onHide={handleClose} centered>

            <Modal.Header closeButton className="modal-header">
                <h1 className="modal-title">Password restoration</h1>
            </Modal.Header>

            <Modal.Body>
                {
                    !sent ? (
                        <FloatingLabel controlId="floatingInput" label="Enter account email" className="mb-2">
                            <Form.Control type="email" placeholder="name@example.com" value={email} onChange={e => setEmail(e.target.value)} />
                        </FloatingLabel>
                    ) : (
                        <>
                            <h2 className="h5">Your email is on the way.</h2>
                            <p>
                                Check your inbox and spam folder.
                                <br/><br/>
                                If you don't receive an email within an hour, please, try to submit this form again or contact support.
                            </p>
                        </>
                    )
                }
            </Modal.Body>
            
            {
                !sent && (
                    <Modal.Footer>
                        <Button className="btn-secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button className="btn-main" onClick={forgotPassword}>
                            Submit
                        </Button>
                    </Modal.Footer>
                )
            }
        </Modal>
    );
}