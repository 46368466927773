
import React, { useEffect } from "react";
import { Placeholder } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'

import imgPopular from '../data/tags/popular.png';
import imgNew from '../data/tags/new.png';
import imgTable from '../data/tags/table.png';
import getImageSrc from "../utils/get-image-src";

export default function GameTile({ game }) {

    // const location = useLocation();
    const locale = useSelector((state) => state.globalContent.data.locale);
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);

    useEffect(() => { })

    if (!game) {
        return (
        <div className="game-tile-placeholder-wrapper">
            <Placeholder as="div" animation="glow" className='mb-1 game-tile-image-placeholder-wrapper'>
                <Placeholder xs={12} className="game-tile-image-placeholder" />
            </Placeholder>
            <Placeholder as="p" animation="glow" className='mb-0'>
                <Placeholder xs={8} size="sm" style={{ borderRadius: '6px' }} />
            </Placeholder>
            <Placeholder as="p" animation="glow" className='mb-0'>
                <Placeholder xs={5} size="sm" style={{ borderRadius: '6px' }} />
            </Placeholder>
        </div>
        )
    }

    return <>
        <Link to={`${userLoggedIn ? `/${locale}/game/${game.id}` : `?register&game=${game.id}` }`} className={`game-tile-container ${game.provider === 'Pragmatic Play' && game.tags.toLowerCase().includes('live') ? 'pragmatic-live' : ''}`} >
            <div className="game-tile-image-wrapper">

                {/* Tags */}
                {
                    !game.tags ? null :
                        <>
                            {
                                game.tags.toLowerCase().includes('new') && (
                                    <div className="tag-container-right">
                                        <img src={imgNew} className="tag-image" alt="" />
                                        <span className="tag-name">New</span>
                                    </div>
                                )
                            }
                            {
                                game.tags.toLowerCase().includes('hot') && (
                                    <div className="tag-container-right">
                                        <img src={imgPopular} className="tag-image" alt="" />
                                        <span className="tag-name">Hot</span>
                                    </div>
                                )
                            }
                            {
                                game.tags.toLowerCase().includes('live') && (
                                    <div className="tag-container-left">
                                        <span className="img-live" ></span>
                                        <span className="tag-name">Live</span>
                                    </div>
                                )
                            }
                            {
                                game.tags.toLowerCase().includes('table') && (
                                    <div className="tag-container-left">
                                        <img src={imgTable} className="tag-image" alt="" />
                                        <span className="tag-name">Table</span>
                                    </div>
                                )
                            }
                        </>
                }

                <div className="game-tile-image-cover" style={{ backgroundImage: `url(${getImageSrc(game.image)}` }}></div>
                <img className="game-tile-image" src={getImageSrc(game.image)} alt={game.name} />

                <div className="game-tile-overlay">
                    <FontAwesomeIcon icon={faPlay} className="game-tile-overlay-icon" />
                </div>
            </div>
            <p className="game-tile-title">{game.name}</p>
            <p className="game-tile-provider">{game.provider}</p>
        </Link>
    </>
}