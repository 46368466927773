import React, { useEffect, useState, useRef, useCallback } from "react";
import { dbGetGatewayOptions, dbPraxisAPMDeposit, dbCorefyDeposit } from '../utils/db-functions';
import { DropdownButton, Dropdown, Button, Spinner, FloatingLabel, Form, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle, faChevronRight, faCreditCard, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import Loading from '../components/Loading';
import DepositSummary from "./DepositSummary";

import 'dotenv/config';

export default function CorefyRedirectDeposit({ amount, promoCode, paymentMethod }) {
    const user = useSelector((state) => state.user.data);

    const [firstName, setFirstName] = useState(user.firstName || '');
    const [lastName, setLastName] = useState(user.lastName || '');
    const [email, setEmail] = useState(user.email || '');
    const [phone, setPhone] = useState(user.phone || '');
    const [street, setStreet] = useState(user.street || '');
    const [city, setCity] = useState(user.city || '');
    const [state, setState] = useState(user.state || '');
    const [country, setCountry] = useState(user?.country?.ISOcode?.toUpperCase() || '');
    const [zip, setZip] = useState(user.zip || '');

    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState('');
    const [redirectUrl, setRedirectUrl] = useState('');

    /** Deposit button */
    const formSubmitHandler = async () => {
        setIsProcessing(true);

        const data = {
            service: paymentMethod,
            currency: user?.currency?.code,
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone: phone,
            street: street,
            city: city,
            state: state,
            zip: zip,
            country: country,
            countryISOcode: user?.country?.ISOcode,
            amount: amount,
            promoCode: promoCode,
            return_url: window.location.origin,
        }

        let result;

        try {
            result = await dbCorefyDeposit(data);

            console.log(result?.data)

            if (result?.data?.hpp_url) {
                /** Combine parameters if applicable */
                return setRedirectUrl(result?.data?.hpp_url);
            }

            /** TO DO: check where the error message coming from */
            setIsProcessing(false);
            setError((result?.data?.errors?.length > 0 ? result?.data?.errors[0]?.title : 'Something went wrong.') + ' Please try again or choose a different payment method.');
            setTimeout(() => { setError('') }, 10000)
        } catch (error) {
            console.log(error)
            setIsProcessing(false);
            setError((error?.data?.description || 'Oops, something went wrong') + '. Please try again or choose a different payment method.');
            setTimeout(() => { setError('') }, 10000);
        }
    }

    function redirectClickHandler() {
        window.open(redirectUrl, '_blank');
        setRedirectUrl('');
        setIsProcessing(false);
    }

    return (
        <>
            {/* Initial screen */}
            {
                !redirectUrl ? (
                    <>
                        <DepositSummary sign={user?.currency?.sign} amount={amount} promoCode={promoCode} />
                        <div className="hpf-container animate__animated animate__faster animate__fadeIn" style={{ minHeight: 'auto' }}>
                            {
                                error && (
                                    <div className="hpf-error-wrapper mb-3 animate__animated animate__faster animate__fadeIn">
                                        <div>
                                            <FontAwesomeIcon icon={faExclamationCircle} className="hpf-error-icon" />
                                        </div>
                                        <p className="hpf-error mb-0 white-100">{error}</p>
                                    </div>
                                )
                            }

                            <div className={`hpf-form-wrapper animate__animated animate__faster animate__fadeIn`}>
                                {
                                    {
                                        "payment_card_eur_hpp": (
                                            <CardFields
                                                firstName={firstName}
                                                setFirstName={setFirstName}
                                                lastName={lastName}
                                                setLastName={setLastName}
                                                street={street}
                                                setStreet={setStreet}
                                                city={city}
                                                setCity={setCity}
                                                state={state}
                                                setState={setState}
                                                country={country}
                                                setCountry={setCountry}
                                                zip={zip}
                                                setZip={setZip}
                                            />
                                        ),
                                        "payment_card_cad_hpp": (
                                            <CardFields
                                                firstName={firstName}
                                                setFirstName={setFirstName}
                                                lastName={lastName}
                                                setLastName={setLastName}
                                                street={street}
                                                setStreet={setStreet}
                                                city={city}
                                                setCity={setCity}
                                                state={state}
                                                setState={setState}
                                                country={country}
                                                setCountry={setCountry}
                                                zip={zip}
                                                setZip={setZip}
                                            />
                                        ),
                                        "payment_card_usd_hpp": (
                                            <CardFields
                                                firstName={firstName}
                                                setFirstName={setFirstName}
                                                lastName={lastName}
                                                setLastName={setLastName}
                                                street={street}
                                                setStreet={setStreet}
                                                city={city}
                                                setCity={setCity}
                                                state={state}
                                                setState={setState}
                                                country={country}
                                                setCountry={setCountry}
                                                zip={zip}
                                                setZip={setZip}
                                            />
                                        ),
                                        "payment_card_nzd_hpp": (
                                            <CardFields
                                                firstName={firstName}
                                                setFirstName={setFirstName}
                                                lastName={lastName}
                                                setLastName={setLastName}
                                                street={street}
                                                setStreet={setStreet}
                                                city={city}
                                                setCity={setCity}
                                                state={state}
                                                setState={setState}
                                                country={country}
                                                setCountry={setCountry}
                                                zip={zip}
                                                setZip={setZip}
                                            />
                                        ),
                                        "interac_aggregated_cad_hpp": (
                                            <InteracFields
                                                firstName={firstName}
                                                setFirstName={setFirstName}
                                                lastName={lastName}
                                                setLastName={setLastName}
                                                email={email}
                                                setEmail={setEmail}
                                                phone={phone}
                                                setPhone={setPhone}
                                            />
                                        ),
                                        "coinspaid": (
                                            <CoinspaidFields
                                                email={email}
                                                setEmail={setEmail}
                                            />
                                        ),
                                        "much_better": (
                                            <MuchBetterFields
                                                country={country}
                                                setCountry={setCountry}
                                                phone={phone}
                                                setPhone={setPhone}
                                            />
                                        ),
                                        // "skrill": (
                                        //     <SkrillFields
                                        //         email={email}
                                        //         setEmail={setEmail}
                                        //     />
                                        // ),
                                        // "pay_with_click": (
                                        //     <PayWithClickFields />
                                        // ),
                                        // "revolut": (
                                        //     <PayWithClickFields />
                                        // ),
                                        // "payop": (
                                        //     <PayWithClickFields />
                                        // ),
                                        // "paydo_ewallet": (
                                        //     <PayWithClickFields />
                                        // ),
                                        // "paydo_cards": (
                                        //     <PayWithClickFields />
                                        // )
                                    }[paymentMethod]
                                }

                                <Button onClick={formSubmitHandler} className="btn-supplementary-lg btn-hpf-deposit" disabled={isProcessing}>
                                    {
                                        isProcessing ? (
                                            <>
                                                <div>
                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                    &nbsp;&nbsp;<span>Processing</span>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div>
                                                    <span>Deposit</span>
                                                </div>
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </>
                                        )
                                    }
                                </Button>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="hpf-redirect-container mt-4 animate__animated animate__faster animate__fadeIn">
                        <div className="hpf-redirect-summary">
                            <FontAwesomeIcon icon={faCheckCircle} className="hpf-check-icon mb-2" />
                            <h2 className="title-xs white-100">Success!</h2>
                            <p className="mb-0">Click below to complete your payment.</p>
                        </div>

                        <Button onClick={redirectClickHandler} className="btn-supplementary-lg btn-hpf-redirect">
                            <div>
                                <span>Complete your payment</span>
                            </div>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </Button>
                    </div>
                )
            }
        </>
    )
}

function CardFields({ firstName, setFirstName, lastName, setLastName, street, setStreet, city, setCity, state, setState, country, setCountry, zip, setZip }) {
    return (
        <>
            <Row className="mb-2">
                <Col className="pe-1">
                    <FloatingLabel label="First Name">
                        <Form.Control
                            type="text"
                            value={firstName}
                            onChange={(e) => { setFirstName(e.target.value); }} />
                    </FloatingLabel>
                </Col>
                <Col className="ps-1">
                    <FloatingLabel label="Last Name">
                        <Form.Control
                            type="text"
                            value={lastName}
                            onChange={(e) => { setLastName(e.target.value); }} />
                    </FloatingLabel>
                </Col>
            </Row>
            <FloatingLabel label="Address" className="mb-2">
                <Form.Control
                    type="text"
                    value={street}
                    onChange={(e) => { setStreet(e.target.value); }} />
            </FloatingLabel>
            <Row className="mb-2">
                <Col className="pe-1">
                    <FloatingLabel label="City">
                        <Form.Control
                            type="text"
                            value={city}
                            onChange={(e) => { setCity(e.target.value); }} />
                    </FloatingLabel>
                </Col>
                <Col className="ps-1">
                    <FloatingLabel label="Province/Region">
                        <Form.Control
                            type="text"
                            value={state}
                            onChange={(e) => { setState(e.target.value); }} />
                    </FloatingLabel>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col className="pe-1">
                    <FloatingLabel label="Country code">
                        <Form.Control
                            type="text"
                            value={country}
                            maxLength={2}
                            onChange={(e) => { setCountry(e.target.value); }} />
                    </FloatingLabel>
                </Col>
                <Col className="ps-1">
                    <FloatingLabel label="Zip Code">
                        <Form.Control
                            type="text"
                            value={zip}
                            onChange={(e) => { setZip(e.target.value); }} />
                    </FloatingLabel>
                </Col>
            </Row>
        </>
    )
}

function InteracFields({ firstName, setFirstName, lastName, setLastName, email, setEmail, phone, setPhone }) {
    return (
        <>
            <Row className="mb-2">
                <Col className="pe-1">
                    <FloatingLabel label="First Name">
                        <Form.Control
                            type="text"
                            value={firstName}
                            onChange={(e) => { setFirstName(e.target.value); }} />
                    </FloatingLabel>
                </Col>
                <Col className="ps-1">
                    <FloatingLabel label="Last Name">
                        <Form.Control
                            type="text"
                            value={lastName}
                            onChange={(e) => { setLastName(e.target.value); }} />
                    </FloatingLabel>
                </Col>
            </Row>
            <FloatingLabel label="Email" className="mb-2">
                <Form.Control
                    type="text"
                    value={email}
                    onChange={(e) => { setEmail(e.target.value); }} />
            </FloatingLabel>
            <FloatingLabel label="Phone" className="mb-2">
                <Form.Control
                    type="text"
                    value={phone}
                    onChange={(e) => { setPhone(e.target.value); }} />
            </FloatingLabel>
        </>
    )
}

function CoinspaidFields({ email, setEmail }) {
    return (
        <>
            <FloatingLabel label="Email" className="mb-2">
                <Form.Control
                    type="text"
                    value={email}
                    onChange={(e) => { setEmail(e.target.value); }} />
            </FloatingLabel>
        </>
    )
}

function SkrillFields({ email, setEmail }) {
    return (
        <>
            <FloatingLabel label="Email" className="mb-2">
                <Form.Control
                    type="text"
                    value={email}
                    onChange={(e) => { setEmail(e.target.value); }} />
            </FloatingLabel>
        </>
    )
}

function PayWithClickFields({ email, setEmail }) {
    return (
        <>
            {/* <FloatingLabel label="Email" className="mb-2">
                <Form.Control
                    type="text"
                    value={email}
                    onChange={(e) => { setEmail(e.target.value); }} />
            </FloatingLabel> */}
        </>
    )
}

function MuchBetterFields({ phone, setPhone, country, setCountry }) {
    return (
        <>
            <FloatingLabel label="Country" className="mb-2">
                <Form.Control
                    type="text"
                    value={country}
                    onChange={(e) => { setCountry(e.target.value); }} />
            </FloatingLabel>
            <FloatingLabel label="Phone" className="mb-2">
                <Form.Control
                    type="text"
                    value={phone}
                    onChange={(e) => { setPhone(e.target.value); }} />
            </FloatingLabel>
        </>
    )
}

/**
 * Coinspaid:
 * gateway: "099960426e4bcef9def8e0f6f2f10832"
 * fields:
 * key: email
 * key: wallet_address ?
 */


/**
 * Much Better:
 * gateway: "f480b7a9643ec6512408c2728d866aeb"
 * fields:
 * 
 * key: phone ?
 * label: "Phone Number in international format" max 50
 * 
 * key: country
 * label: "Country", max 50
 */