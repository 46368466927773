import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Button, Form, FloatingLabel, Alert } from 'react-bootstrap';
// import { dbUpdateUserInfo } from '../utils/db-functions';
import uniqid from 'uniqid';

import './playerInformationForm.scss';

export default function PlayerInformationForm() {
    const user = useSelector((state) => state.user.data);
    const disabled = true;

    const [email, setEmail] = useState(user.email);
    const [firstName, setFirstName] = useState(user.firstName);
    const [lastName, setLastName] = useState(user.lastName);
    const [dateOfBirth, setDateOfBirth] = useState(user.dob);
    const [gender, setGender] = useState(user.gender);
    const [street, setStreet] = useState(user.street);
    const [city, setCity] = useState(user.city);
    const [state, setState] = useState(user.state);
    const [zip, setZip] = useState(user.zip);
    const [phone, setPhone] = useState(user.phone);

    // const updateUserInfo = () => {

    //     /** Do not allow updates if user is verified */
    //     if (user.verified) { return; }

    //     const data = {
    //         firstName: firstName,
    //         lastName: lastName,
    //         street: street,
    //         city: city,
    //         state: state,
    //         zip: zip,
    //         phone: phone,
    //         dob: dateOfBirth,
    //         gender: gender,
    //     }

    //     dbUpdateUserInfo(data, user.id)
    //         .catch(err => { console.log(err) });
    // }

    useEffect(() => { },)


    return (
        <>
            <Alert variant="dark" className="personal-information-alert">
                If your payment information is different from what you provided below, please update it during deposit.
            </Alert>
            <Form noValidate>
                {/* First name */}
                <Form.Group className="mb-2">
                    <FloatingLabel label="First Name (as shown on your ID)" >
                        <Form.Control
                            type="text"
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                            disabled={disabled} />
                        {/* <Form.Control.Feedback type="invalid">Please enter your first name.</Form.Control.Feedback> */}
                    </FloatingLabel>
                </Form.Group>

                {/* Last name */}
                <Form.Group className="mb-2">
                    <FloatingLabel label="Last Name (as shown on your ID)">
                        <Form.Control
                            type="text"
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                            disabled={disabled} />
                        {/* <Form.Control.Feedback type="invalid">Please enter your last name.</Form.Control.Feedback> */}
                    </FloatingLabel>
                </Form.Group>

                {/* Birth date */}
                <Form.Group className="mb-2">
                    <FloatingLabel label="Date of Birth" >
                        <Form.Control
                            type="date"
                            value={dateOfBirth}
                            onChange={e => setDateOfBirth(e.target.value)}
                            disabled={disabled} />
                    </FloatingLabel>
                    {/* <Form.Control type="date" name='date_of_birth' /> */}
                </Form.Group>

                {/* Gender */}
                {/* <Form.Group controlId="currencySelect" className="mb-2">
                    <FloatingLabel label="Gender">
                        <Form.Select
                            aria-label="Gender"
                            value={gender}
                            onChange={e => setGender(e.target.value)}
                            disabled={disabled}
                        >
                            <option value='m'>Male</option>
                            <option value='f'>Female</option>
                        </Form.Select>
                    </FloatingLabel>
                </Form.Group> */}

                {/* Address */}
                <Form.Group className="mb-2">
                    <FloatingLabel label="Street" >
                        <Form.Control
                            type="text"
                            value={street}
                            onChange={e => setStreet(e.target.value)}
                            disabled={disabled} />
                        {/* <Form.Control.Feedback type="invalid">Please enter your first name.</Form.Control.Feedback> */}
                    </FloatingLabel>
                </Form.Group>

                {/* City */}
                <Form.Group className="mb-2">
                    <FloatingLabel label="City" >
                        <Form.Control
                            type="text"
                            value={city}
                            onChange={e => setCity(e.target.value)}
                            disabled={disabled} />
                        {/* <Form.Control.Feedback type="invalid">Please enter your first name.</Form.Control.Feedback> */}
                    </FloatingLabel>
                </Form.Group>

                {/* State */}
                <Form.Group className="mb-2">
                    <FloatingLabel label="Province" >
                        {/* <Form.Control
                        type="text"
                        value={state}
                        onChange={e => setState(e.target.value)}
                        disabled={disabled} /> */}
                        <Form.Select
                            aria-label="Province"
                            value={state}
                            onChange={e => setState(e.target.value)}
                            disabled={disabled}
                        >
                            <option key={uniqid()} value="">None</option>
                            <option key={uniqid()} value="AB">AB</option>
                            <option key={uniqid()} value="BC">BC</option>
                            <option key={uniqid()} value="MB">MB</option>
                            <option key={uniqid()} value="NB">NB</option>
                            <option key={uniqid()} value="NL">NL</option>
                            <option key={uniqid()} value="NT">NT</option>
                            <option key={uniqid()} value="NS">NS</option>
                            <option key={uniqid()} value="NU">NU</option>
                            <option key={uniqid()} value="ON">ON</option>
                            <option key={uniqid()} value="PE">PE</option>
                            <option key={uniqid()} value="QC">QC</option>
                            <option key={uniqid()} value="SK">SK</option>
                            <option key={uniqid()} value="YU">YU</option>
                        </Form.Select>
                    </FloatingLabel>
                </Form.Group>

                {/* Postal code */}
                <Form.Group controlId="postalCode" className="mb-2">
                    <FloatingLabel label="Postal code">
                        <Form.Control
                            type="text"
                            value={zip}
                            onChange={e => setZip(e.target.value)}
                            disabled={disabled} />
                    </FloatingLabel>
                </Form.Group>

                {/* Phone */}
                <Form.Group controlId="postalCode" className="mb-2">
                    <FloatingLabel label="Phone">
                        <Form.Control
                            type="text"
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                            disabled={disabled} />
                    </FloatingLabel>
                </Form.Group>

                {/* Country */}
                <Form.Group controlId="countrySelect" className="mb-2">
                    <FloatingLabel label="Country">
                        <Form.Control
                            type="text"
                            value={user.country.name}
                            disabled />
                    </FloatingLabel>
                </Form.Group>

                {/* Email */}
                <FloatingLabel label="Email address" className="mb-2">
                    <Form.Control
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        disabled />
                    {/* <Form.Control.Feedback type="invalid">Please input a valid email.</Form.Control.Feedback> */}
                </FloatingLabel>

                {/* <div className="text-end mt-3">
                <Button className="btn-supplementary" onClick={updateUserInfo} disabled={user.verified}>Save</Button>
            </div> */}
            </Form>
        </>
    )
}